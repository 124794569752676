/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function (n, ord) {
      var s = String(n).split('.'),
        v0 = !s[1];
      if (ord) return 'other';
      return n == 1 && v0 ? 'one' : 'other';
    },
  },
  messages: {
    'boat.instantBookable': 'Instant bookable',
    'boat.length': 'Length',
    'boat.name': 'Name',
    'boat.passengers': 'Passengers',
    'boat.price': 'Price',
    'boat.region': 'Region',
    'boatDescription.boatDetails': 'Boat details',
    'boatDetail.pricingModal.description': 'Offered charter packages. Prices excludes optional items.',
    'boatDetail.pricingModal.heading': 'Pricing',
    'boatFeatures.features.heading': 'Features',
    'boatFeatures.specifications.heading': 'Specifications',
    'boatLoacation.description': 'Exact location information is provided after a booking is confirmed by the owner.',
    'boatLoacation.heading': 'Boat location',
    'boatPrice.label': 'Price',
    'boatSize.label': 'Boat size',
    'boats.features.title': 'Features',
    'boats.instantBookable.title': 'Instant bookable',
    'boats.keyData.passengers.first': 'Up to',
    'boats.keyData.passengers.second': 'passengers',
    'boatsFilter.date': 'Date',
    'boatsFilter.filters': 'Filters',
    'boatsList.found': 'found',
    'boatsList.noBoatsIn': 'No boats in ',
    'boatsList.searchingBoatsIn': 'Searching boats in',
    'boatsSort.options.hightToLow': 'Price: high to low',
    'boatsSort.options.largeToSmall': 'Size: large to small',
    'boatsSort.options.lowToHigh': 'Price: low to high',
    'boatsSort.options.newest': 'Newest',
    'boatsSort.options.recommended': 'Recommended',
    'boatsSort.options.smallToLarge': 'Size: small to large',
    'booking.bookingContent.additionalOptions': 'Additional Options',
    'booking.bookingContent.cta.requestToBook': 'Request to book',
    'booking.bookingContent.heading': 'Enter your booking details',
    'booking.bookingModalError.description.1': 'If the problem persists, please',
    'booking.bookingModalError.description.2': 'contact our support',
    'booking.bookingModalError.description.3': 'to help you finish your order',
    'booking.bookingModalError.heading': 'Something went wrong while dispatching your order.',
    'booking.dateInput.tripDate': 'Trip date',
    'booking.inquiry': 'Inquiry',
    'booking.priceCalculator.tax': 'Tax',
    'booking.priceCalculator.totalAmount': 'Total amount',
    'bookingOptions.description': ' Please select your booking option. All fees are shown at checkout.',
    'bookingOptions.heading': 'Booking options',
    'bookingOptions.seeAll': 'See all options',
    'bookingOptions.seeAllPrices': 'See all prices',
    'charterTypeDuration.dayCharter': 'Day Charter',
    'charterTypeDuration.multiDayCharter': 'Multi Day Charter',
    'charterTypeDuration.weekCharter': 'Week Charter',
    close: 'Close',
    'contact.call': 'Call',
    'contact.chartersAvailable': 'Overnight and day charters available',
    'contact.description':
      'At Boatsetter, we offer a range of luxury term or day charter options around the globe. Our dedicated White Glove Concierge team will guide you through the planning process to ensure a custom, one-of-a-kind experience for your exclusive charter.',
    'contact.form.additionalDetails': 'Additional details',
    'contact.form.budget': 'Budget',
    'contact.form.dateTo': 'Date to',
    'contact.form.destination': 'Destination',
    'contact.form.email': 'Email',
    'contact.form.firstName': 'First name',
    'contact.form.heading': 'Contact us now using the form below for a personalized quote and recommended itinerary.',
    'contact.form.name': 'Name',
    'contact.form.passengers': 'Passengers',
    'contact.form.phoneNumber': 'Phone number',
    'contact.form.policies':
      'By submitting an inquiry, you are agreeing to Boatsetter terms of service and privacy policy.',
    'contact.form.prefferedDates': 'Preferred dates',
    'contact.form.secondName': 'Last name',
    'contact.form.sent.callUs': 'Call BoatSetter',
    'contact.form.sent.description':
      'Your message has been sent. A Boatsetter representative will contact you shortly.',
    'contact.form.sent.heading': 'Your message has been sent.',
    'contact.form.sentFailed.heading': 'There was an error sending your message',
    'contact.form.sentFailed.tryAgain': 'Try again',
    'contact.form.specialRequests': 'Special requests',
    'contact.luxuryYachts': 'Luxury yachts for charter',
    'contact.sendInquiry': 'Send Inquiry',
    'contact.team.bsTeam': 'Boatsetter team',
    'contact.team.bsTeamDescription':
      'Planning and executing a charter is comparable to a large event with many moving pieces. Our team of trained professionals is comprised of License brokers, Captains, and crew who have first hand experience of what makes a great charter.',
    'contact.team.charterSpecialist': 'Charter Specialist',
    'contact.team.description':
      "Private yacht charters offer customizable luxury while exploring the world's most exotic and exclusive destinations. Make chartering a yacht unforgettable with endless possibilities for a custom experience.",
    'contact.team.directorOfSales': 'Director of Sales',
    'contact.team.heading': 'Why charter a yacht with Boatsetter?',
    'contact.team.salesManager': 'Sales Manager',
    'contact.team.smallerHeading': ' Curate your own luxury charter',
    'errorMessage.2characters': 'Please provide at least 2 characters',
    'errorMessage.4characters': 'Please provide at least 4 characters',
    'errorMessage.emailRequired': 'Email is required.',
    'errorMessage.firstNameRequired': 'First name is required',
    'errorMessage.invalidEmail': 'Invalid email address.',
    'errorMessage.invalidPhone': 'Invalid phone number.',
    'errorMessage.lastNameRequired': 'Last name is required',
    'errorMessage.phoneRequired': 'Phone number is required.',
    'error_page.description': "Looks like you're lost at sea.",
    'error_page.errorCode': 'Error code',
    'error_page.goHome': " Not to worry, we'll help you find your way home.",
    'featuredDestinations.description': 'Find and rent the perfect boat in over 270+ regions.',
    'featuredDestinations.heading': 'Browse our featured destinations',
    'featuredDestinations.seeAll': 'See all our destinations',
    from: 'from',
    'global.currency': 'Currency',
    'global.duration': 'Duration',
    'global.feet': 'feet',
    'global.in': 'in',
    'global.lang': 'Language',
    'global.location': 'Location',
    'global.passengers.plural': 'passengers',
    'global.passengers.singular': 'passenger',
    'global.showMore': 'Show more',
    'global.time': 'Time',
    'instantBookableFilter.instantBook': 'Instant book',
    'mapToggle.hideMap': 'Hide map',
    'mapToggle.showMap': 'Show map',
    'moreFilters.clearAll': 'Clear all',
    'moreFilters.seeResults': 'See results',
    'navigation.aboutBS': 'About Boatsetter',
    'navigation.aboutUs': 'About us',
    'navigation.contact': 'Contact',
    'navigation.contactUs': 'Contact Us',
    'navigation.help': 'Help',
    'navigation.home': 'Home',
    'navigation.howItWorks': 'How It Works',
    'navigation.policies': 'Policies',
    'navigation.popularRegions': 'Popular Regions',
    'navigation.press': 'Press',
    'navigation.privacy': 'Privacy',
    'navigation.reviews': 'Reviews',
    'navigation.seeAllRegions': 'See all regions',
    'navigation.support': 'Support',
    'navigation.terms': 'Terms',
    'noBoatsFound.clearSearch': 'Clear search',
    'noBoatsFound.heading': 'No boats, that match your criteria found.',
    'noBoatsFound.tryAgain': 'Try refining your search.',
    'regions_page.description': 'Find and rent the perfect boat in over 270+ regions.',
    'regions_page.heading': 'Destinations to rent a boat',
    'rentingIsEasy.Cta': 'See how it works',
    'rentingIsEasy.heading': 'Renting a boat is as easy as\u2026',
    'rentingIsEasy.point1.description': 'Explore our privately owned, worldwide fleet',
    'rentingIsEasy.point1.heading': 'Search',
    'rentingIsEasy.point2.description': 'Connect with boat owner and captain',
    'rentingIsEasy.point2.heading': 'Connect',
    'rentingIsEasy.point3.description': 'Set your dates for your boating adventure',
    'rentingIsEasy.point3.heading': 'Book',
    'rentingIsEasy.point4.description': 'Your Boatsetter experience begins',
    'rentingIsEasy.point4.heading': 'Cruise',
    'search.boatsFound': 'boats found',
    'seo.boatDetail.description':
      'Rent a boat, with or without a captain. Powerboats and sailboats, anywhere in the country.',
    'seo.boatDetail.title1': 'Rent a',
    'seo.boatDetail.title2': 'on Boatsetter',
    'seo.boatDetailNotFound.title': 'Boat not found',
    'seo.contact.description':
      'Charter a luxury yacht near you anytime, anywhere with Boatsetter. Talk with our concierge team of yacht experts who will plan your superyacht or mega yacht charter anywhere in the world. Contact us today!',
    'seo.contact.title': 'Contact us',
    'seo.description_default': 'Rent a boat, powerboat or sailboat, anywhere in the world.',
    'seo.og_image_default':
      'https://digital.hbs.edu/platform-digit/wp-content/uploads/sites/2/2020/03/Boatsetter-5.jpg',
    'seo.og_site_name': 'Boatsteer',
    'seo.search.description':
      'Rent a boat, with or without a captain. Powerboats and sailboats, anywhere in the country.',
    'seo.search.title': 'Best boat rentals near me',
    'seo.shared.og_email': 'todo@ogemail.com',
    'seo.title_default': 'Rent a boat, powerboat or sailboat, anywhere in the world.',
    'shareBoat.text': 'Check this boat!',
    'shareBox.coppied': 'Coppied',
    'shareBox.shareThisBoat': 'Share this boat',
    'similarBoats.heading': 'Similar boats',
  },
};
