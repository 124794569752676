import { BoatsColumns } from '~/api/client/api';

const defaultBoatSearchOptions = {
  sortingOption: BoatsColumns.SortPriority,
  pageSize: 18,
  pageNumber: 1,
  ascending: false,
  passengersCount: 0,
  priceFrom: 0,
  boatSizeFrom: 0,
  isInstantBookable: false,
};

const defaultFilterValues = {
  minPrice: 0,
  maxPrice: 15000,
  minBoatSize: 0,
  maxBoatSize: 150,
  maxPassengers: 30,
  minPassengers: 0,
};

export { defaultBoatSearchOptions, defaultFilterValues };
