export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const astronomerTrack = (eventName: string, callback = null) => {
  try {
    if (!window.analytics) return;

    const gas = window.ga?.getAll();
    let clientId = null;

    if (gas) {
      clientId = gas?.[0]?.get('clientId');
    }

    window.analytics.track(
      eventName,
      {
        ga: clientId,
        page_path: window.location.pathname,
        referrer_url: document.referrer,
        _gcl_aw: getCookie('_gcl_aw'),
      },
      {},
      callback
    );
  } catch (e) {
    console.error('TRACKING ERROR', e);
  }
};

const astronomerTrackOrderCreated = function (id, callback = null) {
  const gas = window.ga?.getAll();
  let clientId = null;

  if (gas) {
    clientId = gas?.[0]?.get('clientId');
  }

  window.analytics.track(
    'js_booking_created',
    {
      order_id: id,
      ga: clientId,
      page_path: window.location.pathname,
      referrer_url: document.referrer,
      _gcl_aw: getCookie('_gcl_aw'),
    },
    {},
    callback
  );
};

const trackIdentifyEvent = function (payLoad = {}) {
  console.log('trackIdentifyEvent');

  console.log('trackIdentifyEvent', 'loaded');

  try {
    const gas = window?.ga?.getAll();
    let clientId = null;

    if (gas) {
      clientId = gas?.[0]?.get('clientId');
    }

    if (clientId) {
      window?.analytics?.identify(getCookie('uuid'), {
        ga_client_id: clientId,
        ...payLoad,
      });
    }
  } catch {
    window.addEventListener('load', (event) => {
      trackIdentifyEvent();
    });
  }

  window.addEventListener('load', (event) => {
    trackIdentifyEvent();
  });
};

export { astronomerTrack, astronomerTrackOrderCreated, trackIdentifyEvent };
