import { supportedLangs } from './langUtils';

const supportedCurrencies: string[] = ['eur', 'usd', 'gbp'];

const getCurrencyCharacter = (currencyCode: string) => {
  let currencyCharacter = '$';

  switch (currencyCode) {
    case 'eur':
      currencyCharacter = '€';
      break;
    case 'gbp':
      currencyCharacter = '£';
      break;
    default:
      currencyCharacter = '$';
  }

  return currencyCharacter;
};

const getCurrencyString = (currencyCode: string, amount: any, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  let numberFormat = 'en-US';
  if (currencyCode.toUpperCase() === 'EUR') numberFormat = 'es-Es';

  return new Intl.NumberFormat(numberFormat, {
    style: 'currency',
    currency: currencyCode.toUpperCase(),
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(parseFloat(amount));
};

const getCurrencyBasedOnURL = (token) => {
  let clientCurrency;

  const cultureToken = token;

  const langFromCultureToken = cultureToken.split('-')[0];

  if (cultureToken.includes('/en-us')) {
    clientCurrency = 'usd';
  } else if (cultureToken.includes('-us') && supportedLangs.includes(langFromCultureToken)) {
    clientCurrency = 'usd';
  } else if (cultureToken.includes('-gb') && supportedLangs.includes(langFromCultureToken)) {
    clientCurrency = 'gbp';
  } else if (cultureToken.includes('-ca') && supportedLangs.includes(langFromCultureToken)) {
    clientCurrency = 'usd';
  } else if (cultureToken.includes('-mx') && supportedLangs.includes(langFromCultureToken)) {
    clientCurrency = 'usd';
  } else if (cultureToken.includes('-es') && supportedLangs.includes(langFromCultureToken)) {
    clientCurrency = 'eur';
  } else if (cultureToken.includes('-fr') && supportedLangs.includes(langFromCultureToken)) {
    clientCurrency = 'eur';
  } else if (cultureToken.includes('-it') && supportedLangs.includes(langFromCultureToken)) {
    clientCurrency = 'eur';
  } else if (cultureToken.includes('-de') && supportedLangs.includes(langFromCultureToken)) {
    clientCurrency = 'eur';
  }

  return clientCurrency;
};

export { supportedCurrencies, getCurrencyCharacter, getCurrencyString, getCurrencyBasedOnURL };
