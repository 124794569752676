const defaultLang = 'en';
const supportedCultureTokens: string[] = [
  'en-us',
  'es-us',
  'en-gb',
  'en-ca',
  'fr-fr',
  'fr-ca',
  'es-mx',
  'de-de',
  'es-es',
  'it-it',
];

const supportedLangs: string[] = ['en', 'es', 'de', 'fr', 'it'];

const supportedRegions: string[] = ['us', 'gb', 'ca', 'mx', 'de', 'es', 'it', 'fr'];

const cultureTokensCombinations = [];

for (const item2 of supportedRegions) {
  for (const item1 of supportedLangs) {
    cultureTokensCombinations.push(`${item1}-${item2}`);
  }
}

const getCultureToken = (appCtx) => {
  if (appCtx.state.lang === 'en' && appCtx.state.culture === 'us') return '';
  else return `${appCtx.state.lang}-${appCtx.state.culture}/`;
};
export {
  defaultLang,
  supportedLangs,
  supportedCultureTokens,
  supportedRegions,
  getCultureToken,
  cultureTokensCombinations,
};
