import { defaultBoatSearchOptions } from '~/api/config';
import { BoatsColumns, CharterDurationType } from '~/api/client/api';

const parseUrlToObj = (function (d, x, params, p, i, j) {
  return function (qs) {
    // start bucket; can't cheat by setting it in scope declaration or it overwrites
    params = {};
    // remove preceding non-querystring, correct spaces, and split
    qs = qs
      .substring(qs.indexOf('?') + 1)
      .replace(x, ' ')
      .split('&');
    // march and parse
    for (i = qs.length; i > 0; ) {
      p = qs[--i];
      // allow equals in value
      j = p.indexOf('=');
      // what if no val?
      if (j === -1) params[d(p)] = undefined;
      else params[d(p.substring(0, j))] = d(p.substring(j + 1));
    }

    return params;
  }; // --  fn  deparam
})(decodeURIComponent, /\+/g);

const parseObjToUrl = (params: Object) => {
  const esc = encodeURIComponent;
  const query = Object.keys(params)
    .map((k) => esc(k) + '=' + esc(params[k]))
    .join('&');

  if (query.length > 0) {
    return '?' + query;
  }
  return '';
};

const getQueryParamsForSearch = (query: any, appCtx: any) => {
  const orderByParam = query.sortBy === undefined ? defaultBoatSearchOptions.sortingOption : BoatsColumns[query.sortBy];

  const pageSizeParam = query.pageSize === undefined ? defaultBoatSearchOptions.pageSize : query.pageSize;

  const pageNumberParam = query.pageNumber === undefined ? defaultBoatSearchOptions.pageNumber : query.pageNumber;

  const ascendingParam = query.ascending === undefined ? defaultBoatSearchOptions.ascending : query.ascending;

  const passengerCountParam =
    query.passengerCount === undefined ? defaultBoatSearchOptions.passengersCount : parseInt(query.passengerCount);

  const priceFromParam = query.priceFrom === undefined ? defaultBoatSearchOptions.priceFrom : query.priceFrom;

  const priceToParam = query.priceTo;

  const boatSizeFromParam =
    query.boatSizeFrom === undefined ? defaultBoatSearchOptions.boatSizeFrom : query.boatSizeFrom;

  const boatSizeToParam = query.boatSizeTo;

  const isInstantBookableParam =
    query.isInstantBookable === undefined
      ? defaultBoatSearchOptions.isInstantBookable
      : query.isInstantBookable === 'true';

  const hasAvailableFrom = query.availableFrom !== undefined;
  const hasAvailableTo = query.availableTo !== undefined;

  const durationParam =
    query.duration === undefined ? CharterDurationType.NotSet : JSON.parse(query.duration).join(',');

  const operatorParam = query.operator;

  const boatParams: any = {
    pageSize: pageSizeParam,
    pageNumber: pageNumberParam,
    ascending: ascendingParam,
    orderBy: orderByParam,
    passengerCountMin: passengerCountParam,
    priceFrom: priceFromParam,
    priceTo: priceToParam,
    boatSizeFrom: boatSizeFromParam,
    boatSizeTo: boatSizeToParam,
    isInstantBookable: isInstantBookableParam,
    durations: durationParam,
    ...(operatorParam && { operatorId: operatorParam }),
    latitudeSw: query.latitudeSw,
    longitudeSw: query.longitudeSw,
    latitudeNe: query.latitudeNe,
    longitudeNe: query.longitudeNe,
    xLanguage: appCtx.state.lang,
    xCurrency: appCtx.state.currency,
    automaticallyExpandSearch: query.action !== 'map' && query.pageNumber === undefined,
  };

  if (hasAvailableFrom) {
    boatParams.availableFrom = query.availableFrom;
  }

  if (hasAvailableTo) {
    boatParams.availableTo = query.availableTo;
  }

  if (query.priceTo === undefined) {
    delete boatParams.priceTo;
  }

  if (query.boatSizeTo === undefined) {
    delete boatParams.boatSizeTo;
  }

  return boatParams;
};

export { parseUrlToObj, parseObjToUrl, getQueryParamsForSearch };
