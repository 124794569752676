import React, { useState, useEffect } from 'react';
import { defaultLang, supportedLangs } from '~/utils/langUtils';
import { supportedCurrencies } from '~/utils/currencyUtils';
import { parseUrlToObj } from '~/utils/urlParser';

import catalogEn from '~/public/locales/en/messages';
import catalogEs from '~/public/locales/es/messages';
import catalogDe from '~/public/locales/de/messages';
import catalogFr from '~/public/locales/fr/messages';
import catalogIt from '~/public/locales/it/messages';

import getConfig from 'next/config';

import { I18nProvider } from '@lingui/react';
import { useRouter } from 'next/router';
import { getCookie, trackIdentifyEvent } from '~/utils/metarouter';
const { publicRuntimeConfig } = getConfig();
const catalogs = {
  en: catalogEn,
  es: catalogEs,
  de: catalogDe,
  fr: catalogFr,
  it: catalogIt,
};

interface URLParams {
  currency?: string;
}

// // Decide language based on browser preferences
// const decideLangFromPref = (req: any) => {
//     if (req) {
//         const locale = require('locale');
//
//         const sampleLangs = new locale.Locales(supportedLangs, defaultLang);
//         const prefLangs = new locale.Locales(req.headers['accept-language']);
//         return prefLangs.best(sampleLangs).toString();
//     } else return defaultLang;
// };

interface OrderTypes {
  availableFrom?: string;
  charterTypeId?: string;
  timeFrom?: string;
  passengersCount?: string;
  lastDateSelectedByUser?: string;
  addons?: string;
}
const AppContext = React.createContext({
  state: {
    lang: defaultLang,
    currency: '',
    culture: '',
    defaultCurrency: '',
    loading: false,
  },
  actions: {
    setLang: (value: string) => null,
    setCurrency: (value: string) => null,
    setCulture: (value: string) => null,
    setDefaultCurrency: (value: string) => null,
    setLoading: (value: boolean) => null,
  },
});

const AppProvider = ({ clientLang, clientCurrency, defaultCultureFromHost, defaultCurrencyFromHost, children }) => {
  const [lang, setLang] = useState<string>(clientLang || 'en');
  const [culture, setCulture] = useState<string>(defaultCultureFromHost || 'us');

  const [defaultCurrency, setDefaultCurrency] = useState<string>(defaultCurrencyFromHost);
  const [currency, setCurrency] = useState<string>(clientCurrency);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderParams, setOrderParams] = useState<OrderTypes>({});

  const router = useRouter();
  // useEffect(() => {
  //     console.log(
  //         '%c OrderParams',
  //         'background: #0e0c9b; color: red; font-size: 13px; padding:7px;border-radius:10px',
  //         orderParams
  //     );
  // }, [JSON.stringify(orderParams)]);

  // useEffect(() => {
  //     console.log(
  //         '%c LOADING IS',
  //         'background: black; color: red; font-size: 13px; padding:7px;border-radius:10px',
  //         loading
  //     );
  // }, [loading]);

  useEffect(() => {
    document.documentElement.lang = `${lang}-${culture}`;
  }, [lang, culture]);

  useEffect(() => {
    console.log('RUNNING METAROUTER-------------');

    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('MetaRouter snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'page',
          'once',
          'off',
          'on',
        ];
        analytics.factory = function (t) {
          return function () {
            const e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (let t = 0; t < analytics.methods.length; t++) {
          const e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t) {
          const e = document.createElement('script');
          e.type = 'text/javascript';
          e.async = !0;
          e.src =
            (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'cdn.metarouter.io/a/v1/' + t + '.js';
          const n = document.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(e, n);
        };
        analytics.SNIPPET_VERSION = '3.1.0';
        analytics.page();
        trackIdentifyEvent();
      }
    else {
      const payLoad = {
        path: window.location.pathname,
        url: window.location.href,
        title: document.title,
      };
      analytics.page(payLoad);
      trackIdentifyEvent(payLoad);
    }

    const gaClientId =
      window.ga && window.ga?.getAll ? window.ga?.getAll()[0]?.get('clientId') : getCookie('_ga')?.slice(6);

    // @ts-ignore
    window.rudderanalytics?.page(router.pathname, document.title, {
      ga_client_id: gaClientId,
    });
  }, [router.pathname]);

  return (
    <I18nProvider language={lang} catalogs={catalogs}>
      <AppContext.Provider
        value={{
          state: {
            lang,
            currency,
            culture,
            loading,
            defaultCurrency,
          },
          actions: {
            setLang,
            setCurrency,
            setCulture,
            setLoading,
            setDefaultCurrency,
          },
        }}
      >
        {children}
      </AppContext.Provider>
    </I18nProvider>
  );
};

AppProvider.getInitialProps = async ({ req, res, asPath, pathname }) => {
  // console.log('asPath', asPath);
  // console.log('pathname', pathname);

  // Set header for CDN
  if (res) {
    res.setHeader('Cache-Control', 'max-age=86400, s-maxage=2629746');
  }

  //* Decide default language, currency and create culture token
  let clientLang = 'en';
  let defaultCurrencyFromHost = 'usd';
  let defaultCultureFromHost = 'us';

  let cultureToken = '';
  if (asPath) {
    // console.log('aspath', asPath);
    const parsedCultureToken = asPath.split('/');
    cultureToken = parsedCultureToken[1];
    // console.log('cultureToken is ', cultureToken);
  }

  console.log('-------------------------CT', cultureToken);

  const langFromCultureToken = cultureToken.split('-')[0];

  if (cultureToken === '' || cultureToken.includes('en-us')) {
    clientLang = 'en';
    defaultCurrencyFromHost = 'usd';
    defaultCultureFromHost = 'us';
  } else if (cultureToken.includes('-us') && supportedLangs.includes(langFromCultureToken)) {
    clientLang = langFromCultureToken;
    defaultCurrencyFromHost = 'usd';
    defaultCultureFromHost = 'us';
  } else if (cultureToken.includes('-gb') && supportedLangs.includes(langFromCultureToken)) {
    clientLang = langFromCultureToken;
    defaultCurrencyFromHost = 'gbp';
    defaultCultureFromHost = 'gb';
  } else if (cultureToken.includes('-ca') && supportedLangs.includes(langFromCultureToken)) {
    clientLang = langFromCultureToken;
    defaultCurrencyFromHost = 'usd';
    defaultCultureFromHost = 'ca';
  } else if (cultureToken.includes('-mx') && supportedLangs.includes(langFromCultureToken)) {
    clientLang = langFromCultureToken;
    defaultCurrencyFromHost = 'usd';
    defaultCultureFromHost = 'mx';
  } else if (cultureToken.includes('-es') && supportedLangs.includes(langFromCultureToken)) {
    clientLang = langFromCultureToken;
    defaultCurrencyFromHost = 'eur';
    defaultCultureFromHost = 'es';
  } else if (cultureToken.includes('-fr') && supportedLangs.includes(langFromCultureToken)) {
    clientLang = langFromCultureToken;
    defaultCurrencyFromHost = 'eur';
    defaultCultureFromHost = 'fr';
  } else if (cultureToken.includes('-it') && supportedLangs.includes(langFromCultureToken)) {
    clientLang = langFromCultureToken;
    defaultCurrencyFromHost = 'eur';
    defaultCultureFromHost = 'it';
  } else if (cultureToken.includes('-de') && supportedLangs.includes(langFromCultureToken)) {
    clientLang = langFromCultureToken;
    defaultCurrencyFromHost = 'eur';
    defaultCultureFromHost = 'de';
  } else if (pathname.includes('/[lang]')) {
    const restrictedRedirectWords = [
      '/public',
      'images',
      'font',
      'fort',
      'favicon',
      'webpack',
      'sitemap',
      'health',
      'json',
      'xml',
    ];
    if (
      res &&
      typeof res.writeHead === 'function' &&
      !res.headersSent &&
      !restrictedRedirectWords.some((word) => req.url.includes(word))
    ) {
      res.writeHead(301, {
        Location: `${publicRuntimeConfig.ASSETS_PREFIX}/`,
      });
      res.end();
    }
  }
  // console.log('clientLang', clientLang);
  // console.log('defaultCurrencyFromHost', defaultCurrencyFromHost);
  // console.log('defaultCultureFromHost', defaultCultureFromHost);

  let clientCurrency = defaultCurrencyFromHost;

  // Parse url parameters
  const parsedUrl: URLParams = parseUrlToObj(asPath);

  // If there is currency in url params, set it to client currency
  if (parsedUrl.hasOwnProperty('currency') && supportedCurrencies.includes(parsedUrl.currency)) {
    clientCurrency = parsedUrl.currency;
  }

  return {
    clientLang,
    clientCurrency,
    defaultCurrencyFromHost,
    defaultCultureFromHost,
  };
};

export { AppContext };
export default AppProvider;
