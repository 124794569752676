// @ts-nocheck

/* tslint:disable */
/* eslint-disable */
/**
 * BoatSetter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * An additional item which can be selected as an extra item for a boat (not same as addon).
 * @export
 * @interface AdditionalItemDto
 */
export interface AdditionalItemDto {
    /**
     * Additional item identifier.
     * @type {number}
     * @memberof AdditionalItemDto
     */
    'Id'?: number;
    /**
     * Additional item name.
     * @type {string}
     * @memberof AdditionalItemDto
     */
    'Label'?: string | null;
    /**
     * Additional item exact price.
     * @type {number}
     * @memberof AdditionalItemDto
     */
    'Price'?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof AdditionalItemDto
     */
    'Currency'?: CurrencyCode;
}


/**
 * An optional addon, can be added on top of the booking (not same as additional item).
 * @export
 * @interface AddonDto
 */
export interface AddonDto {
    /**
     * The addon identifier.
     * @type {number}
     * @memberof AddonDto
     */
    'Id'?: number;
    /**
     * The addon name.
     * @type {string}
     * @memberof AddonDto
     */
    'Label'?: string | null;
    /**
     * The addon exact price.
     * @type {number}
     * @memberof AddonDto
     */
    'Price'?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof AddonDto
     */
    'Currency'?: CurrencyCode;
}


/**
 * 
 * @export
 * @interface BoatAddonDto
 */
export interface BoatAddonDto {
    /**
     * 
     * @type {string}
     * @memberof BoatAddonDto
     */
    'Label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BoatAddonDto
     */
    'Price'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof BoatAddonDto
     */
    'Currency'?: CurrencyCode;
}


/**
 * 
 * @export
 * @interface BoatAttributeDto
 */
export interface BoatAttributeDto {
    /**
     * 
     * @type {string}
     * @memberof BoatAttributeDto
     */
    'Key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BoatAttributeDto
     */
    'Value'?: string | null;
}
/**
 * 
 * @export
 * @interface BoatAvailabilitiesAndPrices
 */
export interface BoatAvailabilitiesAndPrices {
    /**
     * 
     * @type {string}
     * @memberof BoatAvailabilitiesAndPrices
     */
    'From'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoatAvailabilitiesAndPrices
     */
    'Until'?: string;
    /**
     * 
     * @type {Array<BoatAvailabilityAndPriceDto>}
     * @memberof BoatAvailabilitiesAndPrices
     */
    'Prices'?: Array<BoatAvailabilityAndPriceDto> | null;
}
/**
 * 
 * @export
 * @interface BoatAvailabilityAndPriceDto
 */
export interface BoatAvailabilityAndPriceDto {
    /**
     * The charter type id of the fetched from price
     * @type {number}
     * @memberof BoatAvailabilityAndPriceDto
     */
    'FromPriceCharterTypeId'?: number;
    /**
     * Boat starting price for the given date and charter type
     * @type {number}
     * @memberof BoatAvailabilityAndPriceDto
     */
    'FromPrice'?: number;
    /**
     * Day for which the availability is being checked
     * @type {string}
     * @memberof BoatAvailabilityAndPriceDto
     */
    'Day'?: string;
    /**
     * Boolean indicating whether the boat is available for the given Day and charter type
     * @type {boolean}
     * @memberof BoatAvailabilityAndPriceDto
     */
    'Available'?: boolean;
}
/**
 * 
 * @export
 * @interface BoatCharterTypeDto
 */
export interface BoatCharterTypeDto {
    /**
     * Charter type identifier.
     * @type {number}
     * @memberof BoatCharterTypeDto
     */
    'Id'?: number;
    /**
     * Charter type name.
     * @type {string}
     * @memberof BoatCharterTypeDto
     */
    'Label'?: string | null;
    /**
     * Charter type available start times.
     * @type {Array<string>}
     * @memberof BoatCharterTypeDto
     */
    'StartTimes'?: Array<string> | null;
    /**
     * 
     * @type {PricePeriod}
     * @memberof BoatCharterTypeDto
     */
    'Period'?: PricePeriod;
    /**
     * Period count component of charter type duration.
     * @type {number}
     * @memberof BoatCharterTypeDto
     */
    'PeriodCount'?: number;
    /**
     * Text representation of BoatSetter.Api.Models.Boats.BoatCharterTypeDto.Period and BoatSetter.Api.Models.Boats.BoatCharterTypeDto.PeriodCount
     * @type {string}
     * @memberof BoatCharterTypeDto
     */
    'Duration'?: string | null;
    /**
     * Total days of the charter type duration.  Calculated from BoatSetter.Api.Models.Boats.BoatCharterTypeDto.Period and BoatSetter.Api.Models.Boats.BoatCharterTypeDto.PeriodCount and rounded up.
     * @type {number}
     * @memberof BoatCharterTypeDto
     */
    'Days'?: number;
    /**
     * The starting price point for the charter type.
     * @type {number}
     * @memberof BoatCharterTypeDto
     */
    'PriceFrom'?: number;
    /**
     * ID of the location for which this charter type is set
     * @type {number}
     * @memberof BoatCharterTypeDto
     */
    'LocationId'?: number | null;
}


/**
 * 
 * @export
 * @interface BoatCharterTypesDto
 */
export interface BoatCharterTypesDto {
    /**
     * 
     * @type {number}
     * @memberof BoatCharterTypesDto
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoatCharterTypesDto
     */
    'Date'?: string;
    /**
     * 
     * @type {Array<BoatCharterTypeDto>}
     * @memberof BoatCharterTypesDto
     */
    'CharterTypes'?: Array<BoatCharterTypeDto> | null;
}
/**
 * 
 * @export
 * @interface BoatDetailDto
 */
export interface BoatDetailDto {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatDetailDto
     */
    'Id'?: number;
    /**
     * List containing all public gallery pictures
     * @type {Array<PictureDto>}
     * @memberof BoatDetailDto
     */
    'Gallery'?: Array<PictureDto> | null;
    /**
     * 
     * @type {string}
     * @memberof BoatDetailDto
     */
    'Name'?: string | null;
    /**
     * The boat alias containing it\'s SKU (Stock-Keeping-Unit) name and ID
     * @type {string}
     * @memberof BoatDetailDto
     */
    'Alias'?: string | null;
    /**
     * The minimum boat price (fetched from cheapest charter type) excluding additional items and addons.
     * @type {number}
     * @memberof BoatDetailDto
     */
    'FromPrice'?: number;
    /**
     * The charter type duration of the cheapest charter type.
     * @type {string}
     * @memberof BoatDetailDto
     */
    'FromPriceDuration'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof BoatDetailDto
     */
    'FromPriceCurrency'?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof BoatDetailDto
     */
    'Description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BoatDetailDto
     */
    'CancellationPolicy'?: string | null;
    /**
     * The boat capacity for passengers
     * @type {number}
     * @memberof BoatDetailDto
     */
    'PassengerCapacity'?: number;
    /**
     * List of all locations where the boat is offered at
     * @type {Array<BoatLocationDto>}
     * @memberof BoatDetailDto
     */
    'Locations'?: Array<BoatLocationDto> | null;
    /**
     * 
     * @type {Array<BoatAttributeDto>}
     * @memberof BoatDetailDto
     */
    'Attributes'?: Array<BoatAttributeDto> | null;
    /**
     * 
     * @type {Array<BoatPropertyDto>}
     * @memberof BoatDetailDto
     */
    'Properties'?: Array<BoatPropertyDto> | null;
    /**
     * A list of available boat addons.
     * @type {Array<BoatAddonDto>}
     * @memberof BoatDetailDto
     */
    'BoatAddons'?: Array<BoatAddonDto> | null;
    /**
     * A list of boat available charter types.
     * @type {Array<BoatPricingDto>}
     * @memberof BoatDetailDto
     */
    'Pricings'?: Array<BoatPricingDto> | null;
    /**
     * Determines whether the boat can be booked without a need of operator approval.
     * @type {boolean}
     * @memberof BoatDetailDto
     */
    'InstantBookable'?: boolean;
}


/**
 * 
 * @export
 * @interface BoatLocationDto
 */
export interface BoatLocationDto {
    /**
     * Location identifier
     * @type {number}
     * @memberof BoatLocationDto
     */
    'Id'?: number;
    /**
     * Location name
     * @type {string}
     * @memberof BoatLocationDto
     */
    'Name'?: string | null;
    /**
     * Longitude coordinate
     * @type {number}
     * @memberof BoatLocationDto
     */
    'Longitude'?: number | null;
    /**
     * Latitude coordinate
     * @type {number}
     * @memberof BoatLocationDto
     */
    'Latitude'?: number | null;
}
/**
 * 
 * @export
 * @interface BoatPricingDto
 */
export interface BoatPricingDto {
    /**
     * 
     * @type {number}
     * @memberof BoatPricingDto
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoatPricingDto
     */
    'Name'?: string | null;
    /**
     * 
     * @type {PricePeriod}
     * @memberof BoatPricingDto
     */
    'Period'?: PricePeriod;
    /**
     * 
     * @type {number}
     * @memberof BoatPricingDto
     */
    'PeriodCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoatPricingDto
     */
    'Duration'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BoatPricingDto
     */
    'Price'?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof BoatPricingDto
     */
    'Currency'?: CurrencyCode;
    /**
     * 
     * @type {number}
     * @memberof BoatPricingDto
     */
    'LocationId'?: number | null;
}


/**
 * 
 * @export
 * @interface BoatPropertyDto
 */
export interface BoatPropertyDto {
    /**
     * 
     * @type {string}
     * @memberof BoatPropertyDto
     */
    'Key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BoatPropertyDto
     */
    'Value'?: string | null;
}
/**
 * 
 * @export
 * @interface BoatSeasonalPricingsDto
 */
export interface BoatSeasonalPricingsDto {
    /**
     * When the season starts
     * @type {string}
     * @memberof BoatSeasonalPricingsDto
     */
    'From'?: string;
    /**
     * When the season ends
     * @type {string}
     * @memberof BoatSeasonalPricingsDto
     */
    'Until'?: string;
    /**
     * 
     * @type {RepeatPeriod}
     * @memberof BoatSeasonalPricingsDto
     */
    'RepeatPeriod'?: RepeatPeriod;
    /**
     * Name of the season
     * @type {string}
     * @memberof BoatSeasonalPricingsDto
     */
    'SeasonName'?: string | null;
    /**
     * Charter Packages (pricings) available withing the season
     * @type {Array<BoatCharterTypeDto>}
     * @memberof BoatSeasonalPricingsDto
     */
    'Pricings'?: Array<BoatCharterTypeDto> | null;
}


/**
 * 
 * @export
 * @interface BoatSiteMapItemData
 */
export interface BoatSiteMapItemData {
    /**
     * The boat name
     * @type {string}
     * @memberof BoatSiteMapItemData
     */
    'Name'?: string | null;
    /**
     * URL friendly boat name
     * @type {string}
     * @memberof BoatSiteMapItemData
     */
    'Slug'?: string | null;
    /**
     * The boat Id
     * @type {number}
     * @memberof BoatSiteMapItemData
     */
    'Id'?: number;
    /**
     * Id for the location where the boat is available
     * @type {Array<BoatSiteMapLocationDto>}
     * @memberof BoatSiteMapItemData
     */
    'Locations'?: Array<BoatSiteMapLocationDto> | null;
}
/**
 * 
 * @export
 * @interface BoatSiteMapItemDataList
 */
export interface BoatSiteMapItemDataList {
    /**
     * Boats on the current page
     * @type {Array<BoatSiteMapItemData>}
     * @memberof BoatSiteMapItemDataList
     */
    'Boats'?: Array<BoatSiteMapItemData> | null;
    /**
     * Total amount of boats on all pages
     * @type {number}
     * @memberof BoatSiteMapItemDataList
     */
    'TotalCount'?: number;
}
/**
 * 
 * @export
 * @interface BoatSiteMapLocationDto
 */
export interface BoatSiteMapLocationDto {
    /**
     * URL friendly location name
     * @type {string}
     * @memberof BoatSiteMapLocationDto
     */
    'Slug'?: string | null;
    /**
     * The location ID
     * @type {number}
     * @memberof BoatSiteMapLocationDto
     */
    'Id'?: number;
}
/**
 * 
 * @export
 * @interface BoatStartTimesDto
 */
export interface BoatStartTimesDto {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatStartTimesDto
     */
    'Id'?: number;
    /**
     * The charter type identifier.
     * @type {number}
     * @memberof BoatStartTimesDto
     */
    'CharterTypeId'?: number;
    /**
     * A list of all available charter type start times.
     * @type {Array<Time>}
     * @memberof BoatStartTimesDto
     */
    'StartTimes'?: Array<Time> | null;
    /**
     * True if the start times are custom for this boat or are generic for all boats.
     * @type {boolean}
     * @memberof BoatStartTimesDto
     */
    'AreCustom'?: boolean;
}
/**
 * 
 * @export
 * @interface BoatThumbnailDto
 */
export interface BoatThumbnailDto {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatThumbnailDto
     */
    'Id'?: number;
    /**
     * Boat alias (usually used for routing)
     * @type {string}
     * @memberof BoatThumbnailDto
     */
    'Alias'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailDto
     */
    'Length'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailDto
     */
    'Passengers'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BoatThumbnailDto
     */
    'Name'?: string | null;
    /**
     * The boats location identifier.
     * @type {number}
     * @memberof BoatThumbnailDto
     */
    'LocationId'?: number;
    /**
     * Location to which the boat is linked to.
     * @type {string}
     * @memberof BoatThumbnailDto
     */
    'LocationName'?: string | null;
    /**
     * The boat longitude pick up location for current date
     * @type {number}
     * @memberof BoatThumbnailDto
     */
    'LocationLongitude'?: number;
    /**
     * The boat latitude pick up location for current date
     * @type {number}
     * @memberof BoatThumbnailDto
     */
    'LocationLatitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailDto
     */
    'FromPrice'?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof BoatThumbnailDto
     */
    'FromPriceCurrency'?: CurrencyCode;
    /**
     * 
     * @type {boolean}
     * @memberof BoatThumbnailDto
     */
    'InstantBookable'?: boolean;
    /**
     * 
     * @type {PictureDto}
     * @memberof BoatThumbnailDto
     */
    'Picture'?: PictureDto;
    /**
     * A list of boat pictures.
     * @type {Array<PictureDto>}
     * @memberof BoatThumbnailDto
     */
    'Gallery'?: Array<PictureDto> | null;
}


/**
 * 
 * @export
 * @interface BoatThumbnailList
 */
export interface BoatThumbnailList {
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailList
     */
    'LatitudeSw'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailList
     */
    'LongitudeSw'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailList
     */
    'LatitudeNe'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailList
     */
    'LongitudeNe'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailList
     */
    'PageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailList
     */
    'PageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoatThumbnailList
     */
    'TotalRecordsCount'?: number;
    /**
     * 
     * @type {Array<BoatThumbnailDto>}
     * @memberof BoatThumbnailList
     */
    'Data'?: Array<BoatThumbnailDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BoatsColumns = {
    Id: 'Id',
    BoatLength: 'BoatLength',
    BoatCustomId: 'BoatCustomId',
    Name: 'Name',
    Status: 'Status',
    CurrentSeason: 'CurrentSeason',
    Owner: 'Owner',
    Skipper: 'Skipper',
    Operator: 'Operator',
    BookingCount: 'BookingCount',
    SortPriority: 'SortPriority',
    CalendarLink: 'CalendarLink',
    Price: 'Price'
} as const;

export type BoatsColumns = typeof BoatsColumns[keyof typeof BoatsColumns];


/**
 * 
 * @export
 * @interface BookingModel
 */
export interface BookingModel {
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    'ClientFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    'ClientLastName': string;
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    'ClientEmail': string;
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    'ClientPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    'Note'?: string | null;
    /**
     * Last boats search query (containing the information about what filters were used and is saved in booking log).
     * @type {string}
     * @memberof BookingModel
     */
    'LastSearchUrl'?: string | null;
    /**
     * 
     * @type {CartModel}
     * @memberof BookingModel
     */
    'Cart'?: CartModel;
    /**
     * Boolean flag indicating whether the booking is submitted as an inquiry
     * @type {boolean}
     * @memberof BookingModel
     */
    'IsInquiry'?: boolean;
    /**
     * Optional parameter for tune affiliate integration
     * @type {string}
     * @memberof BookingModel
     */
    'TuneAffilliateTransactionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    'AnonymousId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BookingModel
     */
    'GoogleAnalyticsClientId'?: string | null;
    /**
     * 
     * @type {Utm}
     * @memberof BookingModel
     */
    'UtmParams'?: Utm;
}
/**
 * 
 * @export
 * @interface CartDto
 */
export interface CartDto {
    /**
     * List of all items in the booking configuration.
     * @type {Array<CartItemDto>}
     * @memberof CartDto
     */
    'Items'?: Array<CartItemDto> | null;
    /**
     * List of all passthrough items (passthrough items are paid in person once customer arrives to meeting location) in the booking configuration.
     * @type {Array<CartItemDto>}
     * @memberof CartDto
     */
    'PassthroughItems'?: Array<CartItemDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CartDto
     */
    'VatAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CartDto
     */
    'NetAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CartDto
     */
    'TotalAmount'?: number;
    /**
     * Sum of all passthrough items (sums recursively - including passthrough items in the BoatSetter.Api.v1.Models.Cart.CartDto.Items property (BoatSetter.Api.v1.Models.Cart.CartItemDto.PassthroughItems) ).
     * @type {number}
     * @memberof CartDto
     */
    'PassthroughAmount'?: number;
}
/**
 * 
 * @export
 * @interface CartItemDto
 */
export interface CartItemDto {
    /**
     * Recursive children items
     * @type {Array<CartItemDto>}
     * @memberof CartItemDto
     */
    'Children'?: Array<CartItemDto> | null;
    /**
     * Passthrough items of this item.
     * @type {Array<CartItemDto>}
     * @memberof CartItemDto
     */
    'PassthroughItems'?: Array<CartItemDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CartItemDto
     */
    'Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CartItemDto
     */
    'Label'?: string | null;
    /**
     * 
     * @type {VatType}
     * @memberof CartItemDto
     */
    'VatOption'?: VatType;
    /**
     * 
     * @type {number}
     * @memberof CartItemDto
     */
    'VatRate'?: number;
    /**
     * Is calculated based on BoatSetter.Api.v1.Models.Cart.CartItemDto.UnitAmount, BoatSetter.Api.v1.Models.Cart.CartItemDto.VatRate, BoatSetter.Api.v1.Models.Cart.CartItemDto.VatOption
     * @type {number}
     * @memberof CartItemDto
     */
    'VatAmount'?: number;
    /**
     * Is calculated based on BoatSetter.Api.v1.Models.Cart.CartItemDto.UnitAmount, BoatSetter.Api.v1.Models.Cart.CartItemDto.VatRate, BoatSetter.Api.v1.Models.Cart.CartItemDto.VatOption
     * @type {number}
     * @memberof CartItemDto
     */
    'NetAmount'?: number;
    /**
     * Relative amount to BoatSetter.Api.v1.Models.Cart.CartItemDto.VatOption - if Added To Total this amount is equal to BoatSetter.Api.v1.Models.Cart.CartItemDto.NetAmount, if Included In Total this amount is equal to BoatSetter.Api.v1.Models.Cart.CartItemDto.TotalAmount.
     * @type {number}
     * @memberof CartItemDto
     */
    'UnitAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CartItemDto
     */
    'TotalAmount'?: number;
    /**
     * Amount of units
     * @type {number}
     * @memberof CartItemDto
     */
    'Quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CartItemDto
     */
    'VatAmountIncludingChildren'?: number;
    /**
     * 
     * @type {number}
     * @memberof CartItemDto
     */
    'NetAmountIncludingChildren'?: number;
    /**
     * 
     * @type {number}
     * @memberof CartItemDto
     */
    'TotalAmountIncludingChildren'?: number;
    /**
     * 
     * @type {number}
     * @memberof CartItemDto
     */
    'PassthroughAmountIncludingChildren'?: number;
}


/**
 * 
 * @export
 * @interface CartModel
 */
export interface CartModel {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof CartModel
     */
    'BoatId': number;
    /**
     * The charter type identifier.
     * @type {number}
     * @memberof CartModel
     */
    'CharterTypeId': number;
    /**
     * The selected start date of the booking.
     * @type {string}
     * @memberof CartModel
     */
    'Date': string;
    /**
     * 
     * @type {number}
     * @memberof CartModel
     */
    'Passengers'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CartModel
     */
    'AdditionalItemIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<CartModelAddons>}
     * @memberof CartModel
     */
    'Addons'?: Array<CartModelAddons> | null;
}
/**
 * 
 * @export
 * @interface CartModelAddons
 */
export interface CartModelAddons {
    /**
     * 
     * @type {number}
     * @memberof CartModelAddons
     */
    'AddonId': number;
    /**
     * 
     * @type {number}
     * @memberof CartModelAddons
     */
    'Quantity': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CharterDurationType = {
    NotSet: 'NotSet',
    DayCharter: 'DayCharter',
    MultiDayCharter: 'MultiDayCharter',
    WeekCharter: 'WeekCharter'
} as const;

export type CharterDurationType = typeof CharterDurationType[keyof typeof CharterDurationType];


/**
 * 
 * @export
 * @interface CityDto
 */
export interface CityDto {
    /**
     * The city identifier.
     * @type {number}
     * @memberof CityDto
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CityDto
     */
    'Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CityDto
     */
    'Country'?: string | null;
    /**
     * The city alias (usually used for routing purposes).
     * @type {string}
     * @memberof CityDto
     */
    'Alias'?: string | null;
    /**
     * Priority of sorting in the list (highest will display first).
     * @type {number}
     * @memberof CityDto
     */
    'SortPriority'?: number;
    /**
     * 
     * @type {PictureDto}
     * @memberof CityDto
     */
    'Picture'?: PictureDto;
    /**
     * The latitude coordinate of north east corner defining the region area.
     * @type {number}
     * @memberof CityDto
     */
    'LatitudeNE'?: number | null;
    /**
     * The longitude coordinate of north east corner defining the region area.
     * @type {number}
     * @memberof CityDto
     */
    'LongitudeNE'?: number | null;
    /**
     * The latitude coordinate of south west corner defining the region area.
     * @type {number}
     * @memberof CityDto
     */
    'LatitudeSW'?: number | null;
    /**
     * The longitude coordinate of south west corner defining the region area.
     * @type {number}
     * @memberof CityDto
     */
    'LongitudeSW'?: number | null;
    /**
     * The region google maps place id.
     * @type {string}
     * @memberof CityDto
     */
    'PlaceId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatedBookingDto
 */
export interface CreatedBookingDto {
    /**
     * The created booking identifier.
     * @type {number}
     * @memberof CreatedBookingDto
     */
    'Id'?: number;
    /**
     * The created booking GUID.
     * @type {string}
     * @memberof CreatedBookingDto
     */
    'Guid'?: string | null;
    /**
     * The URL where the user should get redirected after successfull booking creation
     * @type {string}
     * @memberof CreatedBookingDto
     */
    'RedirectUrl'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CurrencyCode = {
    NotSet: 'NotSet',
    USD: 'USD',
    EUR: 'EUR',
    CAD: 'CAD',
    CZK: 'CZK',
    DKK: 'DKK',
    NOK: 'NOK',
    GBP: 'GBP',
    AUD: 'AUD',
    AED: 'AED',
    ZAR: 'ZAR',
    INR: 'INR',
    HKD: 'HKD',
    HRK: 'HRK',
    CNY: 'CNY',
    BGN: 'BGN',
    BRL: 'BRL',
    CHF: 'CHF',
    HUF: 'HUF',
    JPY: 'JPY',
    NPR: 'NPR',
    NZD: 'NZD',
    PLN: 'PLN',
    RON: 'RON',
    SEK: 'SEK',
    SGD: 'SGD',
    TRY: 'TRY',
    ARS: 'ARS',
    BDT: 'BDT',
    CLP: 'CLP',
    COP: 'COP',
    EGP: 'EGP',
    GEL: 'GEL',
    GHS: 'GHS',
    IDR: 'IDR',
    ILS: 'ILS',
    KES: 'KES',
    KRW: 'KRW',
    LKR: 'LKR',
    MAD: 'MAD',
    MXN: 'MXN',
    MYR: 'MYR',
    NGN: 'NGN',
    PEN: 'PEN',
    PHP: 'PHP',
    RUB: 'RUB',
    THB: 'THB',
    UAH: 'UAH',
    VND: 'VND',
    PKR: 'PKR'
} as const;

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];


/**
 * 
 * @export
 * @interface EmptyBookingModel
 */
export interface EmptyBookingModel {
    /**
     * 
     * @type {string}
     * @memberof EmptyBookingModel
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof EmptyBookingModel
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof EmptyBookingModel
     */
    'Phone'?: string | null;
    /**
     * Passenger capacity
     * @type {number}
     * @memberof EmptyBookingModel
     */
    'Passengers'?: number;
    /**
     * Estimated Date for the inquiry
     * @type {Array<string>}
     * @memberof EmptyBookingModel
     */
    'Dates'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EmptyBookingModel
     */
    'AdditionalDetails'?: string | null;
    /**
     * Free text for preffered charter destination selection
     * @type {string}
     * @memberof EmptyBookingModel
     */
    'Destination'?: string | null;
    /**
     * Estimated budget selected by the client
     * @type {string}
     * @memberof EmptyBookingModel
     */
    'Budget'?: string | null;
    /**
     * The analytics anonymous id
     * @type {string}
     * @memberof EmptyBookingModel
     */
    'AnonymousId'?: string | null;
    /**
     * The google analytics client id
     * @type {string}
     * @memberof EmptyBookingModel
     */
    'GoogleAnalyticsClientId'?: string | null;
}
/**
 * 
 * @export
 * @interface ISiteMapIndexItem
 */
export interface ISiteMapIndexItem {
    /**
     * 
     * @type {string}
     * @memberof ISiteMapIndexItem
     */
    'LastModified'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LanguageCode = {
    NotSet: 'NotSet',
    English: 'English',
    French: 'French',
    German: 'German',
    Danish: 'Danish',
    Spanish: 'Spanish',
    Norwegian: 'Norwegian',
    Swedish: 'Swedish',
    Dutch: 'Dutch',
    Italian: 'Italian',
    Czech: 'Czech',
    Chinese: 'Chinese',
    Slovak: 'Slovak'
} as const;

export type LanguageCode = typeof LanguageCode[keyof typeof LanguageCode];


/**
 * 
 * @export
 * @interface PictureDto
 */
export interface PictureDto {
    /**
     * 
     * @type {string}
     * @memberof PictureDto
     */
    'Guid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PictureDto
     */
    'OwnerId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PictureDto
     */
    'Extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PictureDto
     */
    'Hostname'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PictureDto
     */
    'Width'?: number;
    /**
     * 
     * @type {number}
     * @memberof PictureDto
     */
    'Height'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PictureDto
     */
    'IsPlaceholder'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PricePeriod = {
    NotSet: 'NotSet',
    Absolute: 'Absolute',
    Second: 'Second',
    Minute: 'Minute',
    AmMinute: 'AmMinute',
    PmMinute: 'PmMinute',
    DaytimeMinute: 'DaytimeMinute',
    NightMinute: 'NightMinute',
    WeekdayMinute: 'WeekdayMinute',
    WeekendMinute: 'WeekendMinute',
    Hour: 'Hour',
    AmHour: 'AmHour',
    PmHour: 'PmHour',
    DaytimeHour: 'DaytimeHour',
    NightHour: 'NightHour',
    WeekdayHour: 'WeekdayHour',
    WeekendHour: 'WeekendHour',
    HalfDay: 'HalfDay',
    HalfDayAm: 'HalfDayAm',
    HalfDayPm: 'HalfDayPm',
    Day: 'Day',
    WeekendDay: 'WeekendDay',
    Weekend: 'Weekend',
    Week: 'Week',
    Month: 'Month'
} as const;

export type PricePeriod = typeof PricePeriod[keyof typeof PricePeriod];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'Type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'Title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'Status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'Detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'Instance'?: string | null;
}
/**
 * 
 * @export
 * @interface RegionDto
 */
export interface RegionDto {
    /**
     * The region identifier.
     * @type {number}
     * @memberof RegionDto
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionDto
     */
    'Name'?: string | null;
    /**
     * The region alias (usually used for routing purposes).
     * @type {string}
     * @memberof RegionDto
     */
    'Alias'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegionDto
     */
    'Description'?: string | null;
    /**
     * The name of region\'s parent (e.g. this region is Miami, parent is Florida).
     * @type {string}
     * @memberof RegionDto
     */
    'ParentName'?: string | null;
    /**
     * 
     * @type {PictureDto}
     * @memberof RegionDto
     */
    'Picture'?: PictureDto;
    /**
     * The latitude coordinate of north east corner defining the region area.
     * @type {number}
     * @memberof RegionDto
     */
    'LatitudeNE'?: number | null;
    /**
     * The longitude coordinate of north east corner defining the region area.
     * @type {number}
     * @memberof RegionDto
     */
    'LongitudeNE'?: number | null;
    /**
     * The latitude coordinate of south west corner defining the region area.
     * @type {number}
     * @memberof RegionDto
     */
    'LatitudeSW'?: number | null;
    /**
     * The longitude coordinate of south west corner defining the region area.
     * @type {number}
     * @memberof RegionDto
     */
    'LongitudeSW'?: number | null;
    /**
     * The region google maps place id.
     * @type {string}
     * @memberof RegionDto
     */
    'PlaceId'?: string | null;
}
/**
 * 
 * @export
 * @interface RegionThumbnailDto
 */
export interface RegionThumbnailDto {
    /**
     * A list of all linked cities.
     * @type {Array<CityDto>}
     * @memberof RegionThumbnailDto
     */
    'Cities'?: Array<CityDto> | null;
    /**
     * 
     * @type {string}
     * @memberof RegionThumbnailDto
     */
    'Name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RegionThumbnailDto
     */
    'Id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RepeatPeriod = {
    NoRepeat: 'NoRepeat',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Biweekly: 'Biweekly',
    Triweekly: 'Triweekly',
    Monthly: 'Monthly',
    Bimonthly: 'Bimonthly',
    Trimonthly: 'Trimonthly',
    Semiannually: 'Semiannually',
    Yearly: 'Yearly',
    FiveMinutes: 'FiveMinutes',
    TenMinutes: 'TenMinutes',
    FifteenMinutes: 'FifteenMinutes',
    TwentyMinutes: 'TwentyMinutes',
    Hourly: 'Hourly'
} as const;

export type RepeatPeriod = typeof RepeatPeriod[keyof typeof RepeatPeriod];


/**
 * 
 * @export
 * @interface Time
 */
export interface Time {
    /**
     * 
     * @type {number}
     * @memberof Time
     */
    'Hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof Time
     */
    'Minutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof Time
     */
    'Seconds'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Time
     */
    'IsZeroTime'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Time
     */
    'TotalSeconds'?: number;
}
/**
 * 
 * @export
 * @interface UnavailableTimeDto
 */
export interface UnavailableTimeDto {
    /**
     * The start of the unavailablity slot.
     * @type {string}
     * @memberof UnavailableTimeDto
     */
    'StartsOn'?: string;
    /**
     * The end of the unavailability slot.
     * @type {string}
     * @memberof UnavailableTimeDto
     */
    'EndsOn'?: string;
    /**
     * 
     * @type {number}
     * @memberof UnavailableTimeDto
     */
    'StartTimestamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnavailableTimeDto
     */
    'EndTimestamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnavailableTimeDto
     */
    'StartsOnMidnightTimeStamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof UnavailableTimeDto
     */
    'EndsOnMidnightTimeStamp'?: number;
}
/**
 * 
 * @export
 * @interface Utm
 */
export interface Utm {
    /**
     * 
     * @type {string}
     * @memberof Utm
     */
    'UtmSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Utm
     */
    'UtmMedium'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Utm
     */
    'UtmCampaign'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Utm
     */
    'UtmTerm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Utm
     */
    'UtmContent'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VatType = {
    Default: 'Default',
    IncludedInTotal: 'IncludedInTotal',
    AddedToTotal: 'AddedToTotal',
    NotAdded: 'NotAdded'
} as const;

export type VatType = typeof VatType[keyof typeof VatType];



/**
 * BoatsApi - axios parameter creator
 * @export
 */
export const BoatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lists boats matching filter parameters including provided geolocation coordinates.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {BoatsColumns} orderBy 
         * @param {number} latitudeSw Specifies latitude of South-West corner.
         * @param {number} longitudeSw Specifies longitude of South-West corner.
         * @param {number} latitudeNe Specifies latitude of North-East corner.
         * @param {number} longitudeNe Specifies longitude of North-East corner.
         * @param {string} [availableFrom] 
         * @param {string} [availableTo] 
         * @param {number} [priceFrom] 
         * @param {number} [priceTo] 
         * @param {number} [boatSizeFrom] 
         * @param {number} [boatSizeTo] 
         * @param {number} [passengerCountMin] 
         * @param {number} [passengerCountMax] 
         * @param {boolean} [isInstantBookable] Filters only boats that can be booked immediately without need of acceptation by boat operator.
         * @param {Array<CharterDurationType>} [durations] Filters only boats that have at least one active pricing of any selected duration (e.g. duration type &#x60;MultiDayCharter&#x60; will search only boats that have a charter with duration 2-6 days)
         * @param {boolean} [ascending] 
         * @param {boolean} [automaticallyExpandSearch] Determines whether the search should be expanded in case of small result subset.
         * @param {number} [operatorId] Optional operator identifier to filter boats only managed by specific operator.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsGet: async (pageSize: number, pageNumber: number, orderBy: BoatsColumns, latitudeSw: number, longitudeSw: number, latitudeNe: number, longitudeNe: number, availableFrom?: string, availableTo?: string, priceFrom?: number, priceTo?: number, boatSizeFrom?: number, boatSizeTo?: number, passengerCountMin?: number, passengerCountMax?: number, isInstantBookable?: boolean, durations?: Array<CharterDurationType>, ascending?: boolean, automaticallyExpandSearch?: boolean, operatorId?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1BoatsGet', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('apiV1BoatsGet', 'pageNumber', pageNumber)
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('apiV1BoatsGet', 'orderBy', orderBy)
            // verify required parameter 'latitudeSw' is not null or undefined
            assertParamExists('apiV1BoatsGet', 'latitudeSw', latitudeSw)
            // verify required parameter 'longitudeSw' is not null or undefined
            assertParamExists('apiV1BoatsGet', 'longitudeSw', longitudeSw)
            // verify required parameter 'latitudeNe' is not null or undefined
            assertParamExists('apiV1BoatsGet', 'latitudeNe', latitudeNe)
            // verify required parameter 'longitudeNe' is not null or undefined
            assertParamExists('apiV1BoatsGet', 'longitudeNe', longitudeNe)
            const localVarPath = `/api/v1/boats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (availableFrom !== undefined) {
                localVarQueryParameter['AvailableFrom'] = (availableFrom as any instanceof Date) ?
                    (availableFrom as any).toISOString() :
                    availableFrom;
            }

            if (availableTo !== undefined) {
                localVarQueryParameter['AvailableTo'] = (availableTo as any instanceof Date) ?
                    (availableTo as any).toISOString() :
                    availableTo;
            }

            if (priceFrom !== undefined) {
                localVarQueryParameter['PriceFrom'] = priceFrom;
            }

            if (priceTo !== undefined) {
                localVarQueryParameter['PriceTo'] = priceTo;
            }

            if (boatSizeFrom !== undefined) {
                localVarQueryParameter['BoatSizeFrom'] = boatSizeFrom;
            }

            if (boatSizeTo !== undefined) {
                localVarQueryParameter['BoatSizeTo'] = boatSizeTo;
            }

            if (passengerCountMin !== undefined) {
                localVarQueryParameter['PassengerCountMin'] = passengerCountMin;
            }

            if (passengerCountMax !== undefined) {
                localVarQueryParameter['PassengerCountMax'] = passengerCountMax;
            }

            if (isInstantBookable !== undefined) {
                localVarQueryParameter['IsInstantBookable'] = isInstantBookable;
            }

            if (durations) {
                localVarQueryParameter['Durations'] = durations;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (ascending !== undefined) {
                localVarQueryParameter['Ascending'] = ascending;
            }

            if (latitudeSw !== undefined) {
                localVarQueryParameter['LatitudeSw'] = latitudeSw;
            }

            if (longitudeSw !== undefined) {
                localVarQueryParameter['LongitudeSw'] = longitudeSw;
            }

            if (latitudeNe !== undefined) {
                localVarQueryParameter['LatitudeNe'] = latitudeNe;
            }

            if (longitudeNe !== undefined) {
                localVarQueryParameter['LongitudeNe'] = longitudeNe;
            }

            if (automaticallyExpandSearch !== undefined) {
                localVarQueryParameter['AutomaticallyExpandSearch'] = automaticallyExpandSearch;
            }

            if (operatorId !== undefined) {
                localVarQueryParameter['OperatorId'] = operatorId;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of addons for boat.
         * @param {number} id The boat identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdAddonsGet: async (id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdAddonsGet', 'id', id)
            const localVarPath = `/api/v1/boats/{id}/addons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of additional items for boat and specific charter type.
         * @param {number} id The boat identifier.
         * @param {number} charterTypeId The charter type identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet: async (id: number, charterTypeId: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet', 'id', id)
            // verify required parameter 'charterTypeId' is not null or undefined
            assertParamExists('apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet', 'charterTypeId', charterTypeId)
            const localVarPath = `/api/v1/boats/{id}/charter-types/{charterTypeId}/additional-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"charterTypeId"}}`, encodeURIComponent(String(charterTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of days in the given time range and informtion if they have a bookable time slot and the starting price for each day
         * @param {number} id The boat identifier.
         * @param {number} charterTypeId The charter type identifier.
         * @param {string} [from] The date for the lower bound of the time interval.
         * @param {string} [until] The date for the upper bound of the time interval.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet: async (id: number, charterTypeId: number, from?: string, until?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet', 'id', id)
            // verify required parameter 'charterTypeId' is not null or undefined
            assertParamExists('apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet', 'charterTypeId', charterTypeId)
            const localVarPath = `/api/v1/boats/{id}/charter-types/{charterTypeId}/availabile-times-and-prices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"charterTypeId"}}`, encodeURIComponent(String(charterTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString() :
                    until;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of available charter start times for given charter type.
         * @param {number} id The boat identifier.
         * @param {number} charterTypeId The charter type identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet: async (id: number, charterTypeId: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet', 'id', id)
            // verify required parameter 'charterTypeId' is not null or undefined
            assertParamExists('apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet', 'charterTypeId', charterTypeId)
            const localVarPath = `/api/v1/boats/{id}/charter-types/{charterTypeId}/start-times`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"charterTypeId"}}`, encodeURIComponent(String(charterTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets data of available charter types for boat at specific date.
         * @param {number} id The boat identifier.
         * @param {string} [date] The date of charter to fetch available charter types for (may vary for different dates due to season settings).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesGet: async (id: number, date?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdCharterTypesGet', 'id', id)
            const localVarPath = `/api/v1/boats/{id}/charter-types`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of seasonal charters
         * @param {number} id The boat identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesSeasonsGet: async (id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdCharterTypesSeasonsGet', 'id', id)
            const localVarPath = `/api/v1/boats/{id}/charter-types/seasons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets boat meta and detail data about the boat.
         * @param {number} id The boat identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdGet: async (id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdGet', 'id', id)
            const localVarPath = `/api/v1/boats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets boats with similar properties (length, price) and near the passed location, if location is provided
         * @param {number} id 
         * @param {number} [locationId] 
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdSimilarBoatsGet: async (id: number, locationId?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdSimilarBoatsGet', 'id', id)
            const localVarPath = `/api/v1/boats/{id}/similar-boats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of unavailable time slots for given boat.
         * @param {number} id The boat identifier.
         * @param {number} [year] Year to get unavailability for (defaults to this year).
         * @param {number} [month] Month to get unavailability for (defaults to this month).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdUnavailableTimesGet: async (id: number, year?: number, month?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1BoatsIdUnavailableTimesGet', 'id', id)
            const localVarPath = `/api/v1/boats/{id}/unavailable-times`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoatsApi - functional programming interface
 * @export
 */
export const BoatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lists boats matching filter parameters including provided geolocation coordinates.
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {BoatsColumns} orderBy 
         * @param {number} latitudeSw Specifies latitude of South-West corner.
         * @param {number} longitudeSw Specifies longitude of South-West corner.
         * @param {number} latitudeNe Specifies latitude of North-East corner.
         * @param {number} longitudeNe Specifies longitude of North-East corner.
         * @param {string} [availableFrom] 
         * @param {string} [availableTo] 
         * @param {number} [priceFrom] 
         * @param {number} [priceTo] 
         * @param {number} [boatSizeFrom] 
         * @param {number} [boatSizeTo] 
         * @param {number} [passengerCountMin] 
         * @param {number} [passengerCountMax] 
         * @param {boolean} [isInstantBookable] Filters only boats that can be booked immediately without need of acceptation by boat operator.
         * @param {Array<CharterDurationType>} [durations] Filters only boats that have at least one active pricing of any selected duration (e.g. duration type &#x60;MultiDayCharter&#x60; will search only boats that have a charter with duration 2-6 days)
         * @param {boolean} [ascending] 
         * @param {boolean} [automaticallyExpandSearch] Determines whether the search should be expanded in case of small result subset.
         * @param {number} [operatorId] Optional operator identifier to filter boats only managed by specific operator.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsGet(pageSize: number, pageNumber: number, orderBy: BoatsColumns, latitudeSw: number, longitudeSw: number, latitudeNe: number, longitudeNe: number, availableFrom?: string, availableTo?: string, priceFrom?: number, priceTo?: number, boatSizeFrom?: number, boatSizeTo?: number, passengerCountMin?: number, passengerCountMax?: number, isInstantBookable?: boolean, durations?: Array<CharterDurationType>, ascending?: boolean, automaticallyExpandSearch?: boolean, operatorId?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatThumbnailList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsGet(pageSize, pageNumber, orderBy, latitudeSw, longitudeSw, latitudeNe, longitudeNe, availableFrom, availableTo, priceFrom, priceTo, boatSizeFrom, boatSizeTo, passengerCountMin, passengerCountMax, isInstantBookable, durations, ascending, automaticallyExpandSearch, operatorId, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of addons for boat.
         * @param {number} id The boat identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdAddonsGet(id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdAddonsGet(id, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of additional items for boat and specific charter type.
         * @param {number} id The boat identifier.
         * @param {number} charterTypeId The charter type identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet(id: number, charterTypeId: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdditionalItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet(id, charterTypeId, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of days in the given time range and informtion if they have a bookable time slot and the starting price for each day
         * @param {number} id The boat identifier.
         * @param {number} charterTypeId The charter type identifier.
         * @param {string} [from] The date for the lower bound of the time interval.
         * @param {string} [until] The date for the upper bound of the time interval.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet(id: number, charterTypeId: number, from?: string, until?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatAvailabilitiesAndPrices>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet(id, charterTypeId, from, until, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of available charter start times for given charter type.
         * @param {number} id The boat identifier.
         * @param {number} charterTypeId The charter type identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet(id: number, charterTypeId: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatStartTimesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet(id, charterTypeId, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets data of available charter types for boat at specific date.
         * @param {number} id The boat identifier.
         * @param {string} [date] The date of charter to fetch available charter types for (may vary for different dates due to season settings).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdCharterTypesGet(id: number, date?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatCharterTypesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdCharterTypesGet(id, date, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of seasonal charters
         * @param {number} id The boat identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdCharterTypesSeasonsGet(id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoatSeasonalPricingsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdCharterTypesSeasonsGet(id, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets boat meta and detail data about the boat.
         * @param {number} id The boat identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdGet(id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdGet(id, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets boats with similar properties (length, price) and near the passed location, if location is provided
         * @param {number} id 
         * @param {number} [locationId] 
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdSimilarBoatsGet(id: number, locationId?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoatThumbnailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdSimilarBoatsGet(id, locationId, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of unavailable time slots for given boat.
         * @param {number} id The boat identifier.
         * @param {number} [year] Year to get unavailability for (defaults to this year).
         * @param {number} [month] Month to get unavailability for (defaults to this month).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BoatsIdUnavailableTimesGet(id: number, year?: number, month?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnavailableTimeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BoatsIdUnavailableTimesGet(id, year, month, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoatsApi - factory interface
 * @export
 */
export const BoatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Lists boats matching filter parameters including provided geolocation coordinates.
         * @param {BoatsApiApiV1BoatsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsGet(requestParameters: BoatsApiApiV1BoatsGetRequest, options?: AxiosRequestConfig): AxiosPromise<BoatThumbnailList> {
            return localVarFp.apiV1BoatsGet(requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.latitudeSw, requestParameters.longitudeSw, requestParameters.latitudeNe, requestParameters.longitudeNe, requestParameters.availableFrom, requestParameters.availableTo, requestParameters.priceFrom, requestParameters.priceTo, requestParameters.boatSizeFrom, requestParameters.boatSizeTo, requestParameters.passengerCountMin, requestParameters.passengerCountMax, requestParameters.isInstantBookable, requestParameters.durations, requestParameters.ascending, requestParameters.automaticallyExpandSearch, requestParameters.operatorId, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of addons for boat.
         * @param {BoatsApiApiV1BoatsIdAddonsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdAddonsGet(requestParameters: BoatsApiApiV1BoatsIdAddonsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AddonDto>> {
            return localVarFp.apiV1BoatsIdAddonsGet(requestParameters.id, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of additional items for boat and specific charter type.
         * @param {BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AdditionalItemDto>> {
            return localVarFp.apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet(requestParameters.id, requestParameters.charterTypeId, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of days in the given time range and informtion if they have a bookable time slot and the starting price for each day
         * @param {BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGetRequest, options?: AxiosRequestConfig): AxiosPromise<BoatAvailabilitiesAndPrices> {
            return localVarFp.apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet(requestParameters.id, requestParameters.charterTypeId, requestParameters.from, requestParameters.until, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of available charter start times for given charter type.
         * @param {BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGetRequest, options?: AxiosRequestConfig): AxiosPromise<BoatStartTimesDto> {
            return localVarFp.apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet(requestParameters.id, requestParameters.charterTypeId, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets data of available charter types for boat at specific date.
         * @param {BoatsApiApiV1BoatsIdCharterTypesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesGetRequest, options?: AxiosRequestConfig): AxiosPromise<BoatCharterTypesDto> {
            return localVarFp.apiV1BoatsIdCharterTypesGet(requestParameters.id, requestParameters.date, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of seasonal charters
         * @param {BoatsApiApiV1BoatsIdCharterTypesSeasonsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdCharterTypesSeasonsGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesSeasonsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BoatSeasonalPricingsDto>> {
            return localVarFp.apiV1BoatsIdCharterTypesSeasonsGet(requestParameters.id, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets boat meta and detail data about the boat.
         * @param {BoatsApiApiV1BoatsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdGet(requestParameters: BoatsApiApiV1BoatsIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<BoatDetailDto> {
            return localVarFp.apiV1BoatsIdGet(requestParameters.id, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets boats with similar properties (length, price) and near the passed location, if location is provided
         * @param {BoatsApiApiV1BoatsIdSimilarBoatsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdSimilarBoatsGet(requestParameters: BoatsApiApiV1BoatsIdSimilarBoatsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BoatThumbnailDto>> {
            return localVarFp.apiV1BoatsIdSimilarBoatsGet(requestParameters.id, requestParameters.locationId, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of unavailable time slots for given boat.
         * @param {BoatsApiApiV1BoatsIdUnavailableTimesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BoatsIdUnavailableTimesGet(requestParameters: BoatsApiApiV1BoatsIdUnavailableTimesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UnavailableTimeDto>> {
            return localVarFp.apiV1BoatsIdUnavailableTimesGet(requestParameters.id, requestParameters.year, requestParameters.month, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoatsApi - interface
 * @export
 * @interface BoatsApi
 */
export interface BoatsApiInterface {
    /**
     * 
     * @summary Lists boats matching filter parameters including provided geolocation coordinates.
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {BoatsColumns} orderBy 
     * @param {number} latitudeSw Specifies latitude of South-West corner.
     * @param {number} longitudeSw Specifies longitude of South-West corner.
     * @param {number} latitudeNe Specifies latitude of North-East corner.
     * @param {number} longitudeNe Specifies longitude of North-East corner.
     * @param {string} [availableFrom] 
     * @param {string} [availableTo] 
     * @param {number} [priceFrom] 
     * @param {number} [priceTo] 
     * @param {number} [boatSizeFrom] 
     * @param {number} [boatSizeTo] 
     * @param {number} [passengerCountMin] 
     * @param {number} [passengerCountMax] 
     * @param {boolean} [isInstantBookable] Filters only boats that can be booked immediately without need of acceptation by boat operator.
     * @param {Array<CharterDurationType>} [durations] Filters only boats that have at least one active pricing of any selected duration (e.g. duration type &#x60;MultiDayCharter&#x60; will search only boats that have a charter with duration 2-6 days)
     * @param {boolean} [ascending] 
     * @param {boolean} [automaticallyExpandSearch] Determines whether the search should be expanded in case of small result subset.
     * @param {number} [operatorId] Optional operator identifier to filter boats only managed by specific operator.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsGet(pageSize: number, pageNumber: number, orderBy: BoatsColumns, latitudeSw: number, longitudeSw: number, latitudeNe: number, longitudeNe: number, availableFrom?: string, availableTo?: string, priceFrom?: number, priceTo?: number, boatSizeFrom?: number, boatSizeTo?: number, passengerCountMin?: number, passengerCountMax?: number, isInstantBookable?: boolean, durations?: Array<CharterDurationType>, ascending?: boolean, automaticallyExpandSearch?: boolean, operatorId?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<BoatThumbnailList>;

    /**
     * 
     * @summary Gets a list of addons for boat.
     * @param {number} id The boat identifier.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdAddonsGet(id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<AddonDto>>;

    /**
     * 
     * @summary Gets a list of additional items for boat and specific charter type.
     * @param {number} id The boat identifier.
     * @param {number} charterTypeId The charter type identifier.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet(id: number, charterTypeId: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<AdditionalItemDto>>;

    /**
     * 
     * @summary Get a list of days in the given time range and informtion if they have a bookable time slot and the starting price for each day
     * @param {number} id The boat identifier.
     * @param {number} charterTypeId The charter type identifier.
     * @param {string} [from] The date for the lower bound of the time interval.
     * @param {string} [until] The date for the upper bound of the time interval.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet(id: number, charterTypeId: number, from?: string, until?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<BoatAvailabilitiesAndPrices>;

    /**
     * 
     * @summary Gets a list of available charter start times for given charter type.
     * @param {number} id The boat identifier.
     * @param {number} charterTypeId The charter type identifier.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet(id: number, charterTypeId: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<BoatStartTimesDto>;

    /**
     * 
     * @summary Gets data of available charter types for boat at specific date.
     * @param {number} id The boat identifier.
     * @param {string} [date] The date of charter to fetch available charter types for (may vary for different dates due to season settings).
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdCharterTypesGet(id: number, date?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<BoatCharterTypesDto>;

    /**
     * 
     * @summary Get a list of seasonal charters
     * @param {number} id The boat identifier.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdCharterTypesSeasonsGet(id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<BoatSeasonalPricingsDto>>;

    /**
     * 
     * @summary Gets boat meta and detail data about the boat.
     * @param {number} id The boat identifier.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdGet(id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<BoatDetailDto>;

    /**
     * 
     * @summary Gets boats with similar properties (length, price) and near the passed location, if location is provided
     * @param {number} id 
     * @param {number} [locationId] 
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdSimilarBoatsGet(id: number, locationId?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<BoatThumbnailDto>>;

    /**
     * 
     * @summary Gets a list of unavailable time slots for given boat.
     * @param {number} id The boat identifier.
     * @param {number} [year] Year to get unavailability for (defaults to this year).
     * @param {number} [month] Month to get unavailability for (defaults to this month).
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApiInterface
     */
    apiV1BoatsIdUnavailableTimesGet(id: number, year?: number, month?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<UnavailableTimeDto>>;

}

/**
 * Request parameters for apiV1BoatsGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsGetRequest
 */
export interface BoatsApiApiV1BoatsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly pageSize: number

    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly pageNumber: number

    /**
     * 
     * @type {BoatsColumns}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly orderBy: BoatsColumns

    /**
     * Specifies latitude of South-West corner.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly latitudeSw: number

    /**
     * Specifies longitude of South-West corner.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly longitudeSw: number

    /**
     * Specifies latitude of North-East corner.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly latitudeNe: number

    /**
     * Specifies longitude of North-East corner.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly longitudeNe: number

    /**
     * 
     * @type {string}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly availableFrom?: string

    /**
     * 
     * @type {string}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly availableTo?: string

    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly priceFrom?: number

    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly priceTo?: number

    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly boatSizeFrom?: number

    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly boatSizeTo?: number

    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly passengerCountMin?: number

    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly passengerCountMax?: number

    /**
     * Filters only boats that can be booked immediately without need of acceptation by boat operator.
     * @type {boolean}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly isInstantBookable?: boolean

    /**
     * Filters only boats that have at least one active pricing of any selected duration (e.g. duration type &#x60;MultiDayCharter&#x60; will search only boats that have a charter with duration 2-6 days)
     * @type {Array<CharterDurationType>}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly durations?: Array<CharterDurationType>

    /**
     * 
     * @type {boolean}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly ascending?: boolean

    /**
     * Determines whether the search should be expanded in case of small result subset.
     * @type {boolean}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly automaticallyExpandSearch?: boolean

    /**
     * Optional operator identifier to filter boats only managed by specific operator.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly operatorId?: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdAddonsGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdAddonsGetRequest
 */
export interface BoatsApiApiV1BoatsIdAddonsGetRequest {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdAddonsGet
     */
    readonly id: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdAddonsGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdAddonsGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGetRequest
 */
export interface BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGetRequest {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet
     */
    readonly id: number

    /**
     * The charter type identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet
     */
    readonly charterTypeId: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGetRequest
 */
export interface BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGetRequest {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet
     */
    readonly id: number

    /**
     * The charter type identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet
     */
    readonly charterTypeId: number

    /**
     * The date for the lower bound of the time interval.
     * @type {string}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet
     */
    readonly from?: string

    /**
     * The date for the upper bound of the time interval.
     * @type {string}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet
     */
    readonly until?: string

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGetRequest
 */
export interface BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGetRequest {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet
     */
    readonly id: number

    /**
     * The charter type identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet
     */
    readonly charterTypeId: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdCharterTypesGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdCharterTypesGetRequest
 */
export interface BoatsApiApiV1BoatsIdCharterTypesGetRequest {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesGet
     */
    readonly id: number

    /**
     * The date of charter to fetch available charter types for (may vary for different dates due to season settings).
     * @type {string}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesGet
     */
    readonly date?: string

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdCharterTypesSeasonsGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdCharterTypesSeasonsGetRequest
 */
export interface BoatsApiApiV1BoatsIdCharterTypesSeasonsGetRequest {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesSeasonsGet
     */
    readonly id: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesSeasonsGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdCharterTypesSeasonsGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdGetRequest
 */
export interface BoatsApiApiV1BoatsIdGetRequest {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdGet
     */
    readonly id: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdSimilarBoatsGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdSimilarBoatsGetRequest
 */
export interface BoatsApiApiV1BoatsIdSimilarBoatsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdSimilarBoatsGet
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdSimilarBoatsGet
     */
    readonly locationId?: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdSimilarBoatsGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdSimilarBoatsGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1BoatsIdUnavailableTimesGet operation in BoatsApi.
 * @export
 * @interface BoatsApiApiV1BoatsIdUnavailableTimesGetRequest
 */
export interface BoatsApiApiV1BoatsIdUnavailableTimesGetRequest {
    /**
     * The boat identifier.
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdUnavailableTimesGet
     */
    readonly id: number

    /**
     * Year to get unavailability for (defaults to this year).
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdUnavailableTimesGet
     */
    readonly year?: number

    /**
     * Month to get unavailability for (defaults to this month).
     * @type {number}
     * @memberof BoatsApiApiV1BoatsIdUnavailableTimesGet
     */
    readonly month?: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BoatsApiApiV1BoatsIdUnavailableTimesGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BoatsApiApiV1BoatsIdUnavailableTimesGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * BoatsApi - object-oriented interface
 * @export
 * @class BoatsApi
 * @extends {BaseAPI}
 */
export class BoatsApi extends BaseAPI implements BoatsApiInterface {
    /**
     * 
     * @summary Lists boats matching filter parameters including provided geolocation coordinates.
     * @param {BoatsApiApiV1BoatsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsGet(requestParameters: BoatsApiApiV1BoatsGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsGet(requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.latitudeSw, requestParameters.longitudeSw, requestParameters.latitudeNe, requestParameters.longitudeNe, requestParameters.availableFrom, requestParameters.availableTo, requestParameters.priceFrom, requestParameters.priceTo, requestParameters.boatSizeFrom, requestParameters.boatSizeTo, requestParameters.passengerCountMin, requestParameters.passengerCountMax, requestParameters.isInstantBookable, requestParameters.durations, requestParameters.ascending, requestParameters.automaticallyExpandSearch, requestParameters.operatorId, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of addons for boat.
     * @param {BoatsApiApiV1BoatsIdAddonsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdAddonsGet(requestParameters: BoatsApiApiV1BoatsIdAddonsGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdAddonsGet(requestParameters.id, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of additional items for boat and specific charter type.
     * @param {BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdCharterTypesCharterTypeIdAdditionalItemsGet(requestParameters.id, requestParameters.charterTypeId, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of days in the given time range and informtion if they have a bookable time slot and the starting price for each day
     * @param {BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdCharterTypesCharterTypeIdAvailabileTimesAndPricesGet(requestParameters.id, requestParameters.charterTypeId, requestParameters.from, requestParameters.until, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of available charter start times for given charter type.
     * @param {BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesCharterTypeIdStartTimesGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdCharterTypesCharterTypeIdStartTimesGet(requestParameters.id, requestParameters.charterTypeId, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets data of available charter types for boat at specific date.
     * @param {BoatsApiApiV1BoatsIdCharterTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdCharterTypesGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdCharterTypesGet(requestParameters.id, requestParameters.date, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of seasonal charters
     * @param {BoatsApiApiV1BoatsIdCharterTypesSeasonsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdCharterTypesSeasonsGet(requestParameters: BoatsApiApiV1BoatsIdCharterTypesSeasonsGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdCharterTypesSeasonsGet(requestParameters.id, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets boat meta and detail data about the boat.
     * @param {BoatsApiApiV1BoatsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdGet(requestParameters: BoatsApiApiV1BoatsIdGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdGet(requestParameters.id, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets boats with similar properties (length, price) and near the passed location, if location is provided
     * @param {BoatsApiApiV1BoatsIdSimilarBoatsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdSimilarBoatsGet(requestParameters: BoatsApiApiV1BoatsIdSimilarBoatsGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdSimilarBoatsGet(requestParameters.id, requestParameters.locationId, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of unavailable time slots for given boat.
     * @param {BoatsApiApiV1BoatsIdUnavailableTimesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoatsApi
     */
    public apiV1BoatsIdUnavailableTimesGet(requestParameters: BoatsApiApiV1BoatsIdUnavailableTimesGetRequest, options?: AxiosRequestConfig) {
        return BoatsApiFp(this.configuration).apiV1BoatsIdUnavailableTimesGet(requestParameters.id, requestParameters.year, requestParameters.month, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a booking inquiry without any attatched boat.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {EmptyBookingModel} [emptyBookingModel] Client provided information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BookingsInquiryPost: async (xLanguage?: LanguageCode, xCurrency?: CurrencyCode, emptyBookingModel?: EmptyBookingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookings/inquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emptyBookingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates the booking based on booking model data provided.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {BookingModel} [bookingModel] The BoatSetter.Api.v1.Models.Bookings.BookingModel data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BookingsPost: async (xLanguage?: LanguageCode, xCurrency?: CurrencyCode, bookingModel?: BookingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a booking inquiry without any attatched boat.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {EmptyBookingModel} [emptyBookingModel] Client provided information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BookingsInquiryPost(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, emptyBookingModel?: EmptyBookingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedBookingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BookingsInquiryPost(xLanguage, xCurrency, emptyBookingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates the booking based on booking model data provided.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {BookingModel} [bookingModel] The BoatSetter.Api.v1.Models.Bookings.BookingModel data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1BookingsPost(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, bookingModel?: BookingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedBookingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1BookingsPost(xLanguage, xCurrency, bookingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a booking inquiry without any attatched boat.
         * @param {BookingsApiApiV1BookingsInquiryPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BookingsInquiryPost(requestParameters: BookingsApiApiV1BookingsInquiryPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreatedBookingDto> {
            return localVarFp.apiV1BookingsInquiryPost(requestParameters.xLanguage, requestParameters.xCurrency, requestParameters.emptyBookingModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates the booking based on booking model data provided.
         * @param {BookingsApiApiV1BookingsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1BookingsPost(requestParameters: BookingsApiApiV1BookingsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CreatedBookingDto> {
            return localVarFp.apiV1BookingsPost(requestParameters.xLanguage, requestParameters.xCurrency, requestParameters.bookingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingsApi - interface
 * @export
 * @interface BookingsApi
 */
export interface BookingsApiInterface {
    /**
     * 
     * @summary Creates a booking inquiry without any attatched boat.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {EmptyBookingModel} [emptyBookingModel] Client provided information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    apiV1BookingsInquiryPost(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, emptyBookingModel?: EmptyBookingModel, options?: AxiosRequestConfig): AxiosPromise<CreatedBookingDto>;

    /**
     * 
     * @summary Creates the booking based on booking model data provided.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {BookingModel} [bookingModel] The BoatSetter.Api.v1.Models.Bookings.BookingModel data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    apiV1BookingsPost(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, bookingModel?: BookingModel, options?: AxiosRequestConfig): AxiosPromise<CreatedBookingDto>;

}

/**
 * Request parameters for apiV1BookingsInquiryPost operation in BookingsApi.
 * @export
 * @interface BookingsApiApiV1BookingsInquiryPostRequest
 */
export interface BookingsApiApiV1BookingsInquiryPostRequest {
    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BookingsApiApiV1BookingsInquiryPost
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BookingsApiApiV1BookingsInquiryPost
     */
    readonly xCurrency?: CurrencyCode

    /**
     * Client provided information.
     * @type {EmptyBookingModel}
     * @memberof BookingsApiApiV1BookingsInquiryPost
     */
    readonly emptyBookingModel?: EmptyBookingModel
}

/**
 * Request parameters for apiV1BookingsPost operation in BookingsApi.
 * @export
 * @interface BookingsApiApiV1BookingsPostRequest
 */
export interface BookingsApiApiV1BookingsPostRequest {
    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof BookingsApiApiV1BookingsPost
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof BookingsApiApiV1BookingsPost
     */
    readonly xCurrency?: CurrencyCode

    /**
     * The BoatSetter.Api.v1.Models.Bookings.BookingModel data.
     * @type {BookingModel}
     * @memberof BookingsApiApiV1BookingsPost
     */
    readonly bookingModel?: BookingModel
}

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI implements BookingsApiInterface {
    /**
     * 
     * @summary Creates a booking inquiry without any attatched boat.
     * @param {BookingsApiApiV1BookingsInquiryPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public apiV1BookingsInquiryPost(requestParameters: BookingsApiApiV1BookingsInquiryPostRequest = {}, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).apiV1BookingsInquiryPost(requestParameters.xLanguage, requestParameters.xCurrency, requestParameters.emptyBookingModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates the booking based on booking model data provided.
     * @param {BookingsApiApiV1BookingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public apiV1BookingsPost(requestParameters: BookingsApiApiV1BookingsPostRequest = {}, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).apiV1BookingsPost(requestParameters.xLanguage, requestParameters.xCurrency, requestParameters.bookingModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CartApi - axios parameter creator
 * @export
 */
export const CartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculates amounts of all items selected in cart.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {CartModel} [cartModel] The BoatSetter.Api.v1.Models.Cart.CartModel data (booking configuration).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartCalculatePost: async (xLanguage?: LanguageCode, xCurrency?: CurrencyCode, cartModel?: CartModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cart/calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verifies that the name is in correct format (does not contain any unwanted characters - like #, etc.).
         * @param {string} firstName The first name.
         * @param {string} lastName The last name.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartVerifyNameGet: async (firstName: string, lastName: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firstName' is not null or undefined
            assertParamExists('apiV1CartVerifyNameGet', 'firstName', firstName)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('apiV1CartVerifyNameGet', 'lastName', lastName)
            const localVarPath = `/api/v1/cart/verify-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verifies that the phone number is in correct format.
         * @param {string} phone Formatted phone number.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartVerifyPhoneGet: async (phone: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('apiV1CartVerifyPhoneGet', 'phone', phone)
            const localVarPath = `/api/v1/cart/verify-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartApi - functional programming interface
 * @export
 */
export const CartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculates amounts of all items selected in cart.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {CartModel} [cartModel] The BoatSetter.Api.v1.Models.Cart.CartModel data (booking configuration).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CartCalculatePost(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, cartModel?: CartModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CartCalculatePost(xLanguage, xCurrency, cartModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verifies that the name is in correct format (does not contain any unwanted characters - like #, etc.).
         * @param {string} firstName The first name.
         * @param {string} lastName The last name.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CartVerifyNameGet(firstName: string, lastName: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CartVerifyNameGet(firstName, lastName, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verifies that the phone number is in correct format.
         * @param {string} phone Formatted phone number.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CartVerifyPhoneGet(phone: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CartVerifyPhoneGet(phone, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartApi - factory interface
 * @export
 */
export const CartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculates amounts of all items selected in cart.
         * @param {CartApiApiV1CartCalculatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartCalculatePost(requestParameters: CartApiApiV1CartCalculatePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CartDto> {
            return localVarFp.apiV1CartCalculatePost(requestParameters.xLanguage, requestParameters.xCurrency, requestParameters.cartModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verifies that the name is in correct format (does not contain any unwanted characters - like #, etc.).
         * @param {CartApiApiV1CartVerifyNameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartVerifyNameGet(requestParameters: CartApiApiV1CartVerifyNameGetRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.apiV1CartVerifyNameGet(requestParameters.firstName, requestParameters.lastName, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verifies that the phone number is in correct format.
         * @param {CartApiApiV1CartVerifyPhoneGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CartVerifyPhoneGet(requestParameters: CartApiApiV1CartVerifyPhoneGetRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.apiV1CartVerifyPhoneGet(requestParameters.phone, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CartApi - interface
 * @export
 * @interface CartApi
 */
export interface CartApiInterface {
    /**
     * 
     * @summary Calculates amounts of all items selected in cart.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {CartModel} [cartModel] The BoatSetter.Api.v1.Models.Cart.CartModel data (booking configuration).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApiInterface
     */
    apiV1CartCalculatePost(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, cartModel?: CartModel, options?: AxiosRequestConfig): AxiosPromise<CartDto>;

    /**
     * 
     * @summary Verifies that the name is in correct format (does not contain any unwanted characters - like #, etc.).
     * @param {string} firstName The first name.
     * @param {string} lastName The last name.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApiInterface
     */
    apiV1CartVerifyNameGet(firstName: string, lastName: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @summary Verifies that the phone number is in correct format.
     * @param {string} phone Formatted phone number.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApiInterface
     */
    apiV1CartVerifyPhoneGet(phone: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * Request parameters for apiV1CartCalculatePost operation in CartApi.
 * @export
 * @interface CartApiApiV1CartCalculatePostRequest
 */
export interface CartApiApiV1CartCalculatePostRequest {
    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof CartApiApiV1CartCalculatePost
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof CartApiApiV1CartCalculatePost
     */
    readonly xCurrency?: CurrencyCode

    /**
     * The BoatSetter.Api.v1.Models.Cart.CartModel data (booking configuration).
     * @type {CartModel}
     * @memberof CartApiApiV1CartCalculatePost
     */
    readonly cartModel?: CartModel
}

/**
 * Request parameters for apiV1CartVerifyNameGet operation in CartApi.
 * @export
 * @interface CartApiApiV1CartVerifyNameGetRequest
 */
export interface CartApiApiV1CartVerifyNameGetRequest {
    /**
     * The first name.
     * @type {string}
     * @memberof CartApiApiV1CartVerifyNameGet
     */
    readonly firstName: string

    /**
     * The last name.
     * @type {string}
     * @memberof CartApiApiV1CartVerifyNameGet
     */
    readonly lastName: string

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof CartApiApiV1CartVerifyNameGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof CartApiApiV1CartVerifyNameGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1CartVerifyPhoneGet operation in CartApi.
 * @export
 * @interface CartApiApiV1CartVerifyPhoneGetRequest
 */
export interface CartApiApiV1CartVerifyPhoneGetRequest {
    /**
     * Formatted phone number.
     * @type {string}
     * @memberof CartApiApiV1CartVerifyPhoneGet
     */
    readonly phone: string

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof CartApiApiV1CartVerifyPhoneGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof CartApiApiV1CartVerifyPhoneGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
export class CartApi extends BaseAPI implements CartApiInterface {
    /**
     * 
     * @summary Calculates amounts of all items selected in cart.
     * @param {CartApiApiV1CartCalculatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public apiV1CartCalculatePost(requestParameters: CartApiApiV1CartCalculatePostRequest = {}, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).apiV1CartCalculatePost(requestParameters.xLanguage, requestParameters.xCurrency, requestParameters.cartModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verifies that the name is in correct format (does not contain any unwanted characters - like #, etc.).
     * @param {CartApiApiV1CartVerifyNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public apiV1CartVerifyNameGet(requestParameters: CartApiApiV1CartVerifyNameGetRequest, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).apiV1CartVerifyNameGet(requestParameters.firstName, requestParameters.lastName, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verifies that the phone number is in correct format.
     * @param {CartApiApiV1CartVerifyPhoneGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public apiV1CartVerifyPhoneGet(requestParameters: CartApiApiV1CartVerifyPhoneGetRequest, options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration).apiV1CartVerifyPhoneGet(requestParameters.phone, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnumsApi - axios parameter creator
 * @export
 */
export const EnumsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the currencies.
         * @param {CurrencyCode} [currencyCode] The currency code.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsCurrenciesGet: async (currencyCode?: CurrencyCode, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currencyCode !== undefined) {
                localVarQueryParameter['currencyCode'] = currencyCode;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the languages.
         * @param {LanguageCode} [languageCode] The language code.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsLanguagesGet: async (languageCode?: LanguageCode, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/enums/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnumsApi - functional programming interface
 * @export
 */
export const EnumsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnumsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the currencies.
         * @param {CurrencyCode} [currencyCode] The currency code.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsCurrenciesGet(currencyCode?: CurrencyCode, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsCurrenciesGet(currencyCode, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the languages.
         * @param {LanguageCode} [languageCode] The language code.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnumsLanguagesGet(languageCode?: LanguageCode, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguageCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnumsLanguagesGet(languageCode, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnumsApi - factory interface
 * @export
 */
export const EnumsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnumsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the currencies.
         * @param {EnumsApiApiV1EnumsCurrenciesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsCurrenciesGet(requestParameters: EnumsApiApiV1EnumsCurrenciesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<CurrencyCode>> {
            return localVarFp.apiV1EnumsCurrenciesGet(requestParameters.currencyCode, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the languages.
         * @param {EnumsApiApiV1EnumsLanguagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnumsLanguagesGet(requestParameters: EnumsApiApiV1EnumsLanguagesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<LanguageCode>> {
            return localVarFp.apiV1EnumsLanguagesGet(requestParameters.languageCode, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnumsApi - interface
 * @export
 * @interface EnumsApi
 */
export interface EnumsApiInterface {
    /**
     * 
     * @summary Gets the currencies.
     * @param {CurrencyCode} [currencyCode] The currency code.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApiInterface
     */
    apiV1EnumsCurrenciesGet(currencyCode?: CurrencyCode, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<CurrencyCode>>;

    /**
     * 
     * @summary Gets the languages.
     * @param {LanguageCode} [languageCode] The language code.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApiInterface
     */
    apiV1EnumsLanguagesGet(languageCode?: LanguageCode, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<LanguageCode>>;

}

/**
 * Request parameters for apiV1EnumsCurrenciesGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsCurrenciesGetRequest
 */
export interface EnumsApiApiV1EnumsCurrenciesGetRequest {
    /**
     * The currency code.
     * @type {CurrencyCode}
     * @memberof EnumsApiApiV1EnumsCurrenciesGet
     */
    readonly currencyCode?: CurrencyCode

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof EnumsApiApiV1EnumsCurrenciesGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof EnumsApiApiV1EnumsCurrenciesGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1EnumsLanguagesGet operation in EnumsApi.
 * @export
 * @interface EnumsApiApiV1EnumsLanguagesGetRequest
 */
export interface EnumsApiApiV1EnumsLanguagesGetRequest {
    /**
     * The language code.
     * @type {LanguageCode}
     * @memberof EnumsApiApiV1EnumsLanguagesGet
     */
    readonly languageCode?: LanguageCode

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof EnumsApiApiV1EnumsLanguagesGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof EnumsApiApiV1EnumsLanguagesGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * EnumsApi - object-oriented interface
 * @export
 * @class EnumsApi
 * @extends {BaseAPI}
 */
export class EnumsApi extends BaseAPI implements EnumsApiInterface {
    /**
     * 
     * @summary Gets the currencies.
     * @param {EnumsApiApiV1EnumsCurrenciesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsCurrenciesGet(requestParameters: EnumsApiApiV1EnumsCurrenciesGetRequest = {}, options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsCurrenciesGet(requestParameters.currencyCode, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the languages.
     * @param {EnumsApiApiV1EnumsLanguagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnumsApi
     */
    public apiV1EnumsLanguagesGet(requestParameters: EnumsApiApiV1EnumsLanguagesGetRequest = {}, options?: AxiosRequestConfig) {
        return EnumsApiFp(this.configuration).apiV1EnumsLanguagesGet(requestParameters.languageCode, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegionsApi - axios parameter creator
 * @export
 */
export const RegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a structured list of all regions.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegionsGet: async (xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the region related data.
         * @param {number} id The region identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegionsIdGet: async (id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RegionsIdGet', 'id', id)
            const localVarPath = `/api/v1/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of all promoted regions.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegionsPromotedGet: async (xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/regions/promoted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the region that matches the search word.
         * @param {string} [name] The search word.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegionsSearchGet: async (name?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/regions/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionsApi - functional programming interface
 * @export
 */
export const RegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a structured list of all regions.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RegionsGet(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegionThumbnailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RegionsGet(xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the region related data.
         * @param {number} id The region identifier.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RegionsIdGet(id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RegionsIdGet(id, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of all promoted regions.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RegionsPromotedGet(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RegionsPromotedGet(xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the region that matches the search word.
         * @param {string} [name] The search word.
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RegionsSearchGet(name?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RegionsSearchGet(name, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionsApi - factory interface
 * @export
 */
export const RegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a structured list of all regions.
         * @param {RegionsApiApiV1RegionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegionsGet(requestParameters: RegionsApiApiV1RegionsGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<RegionThumbnailDto>> {
            return localVarFp.apiV1RegionsGet(requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the region related data.
         * @param {RegionsApiApiV1RegionsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegionsIdGet(requestParameters: RegionsApiApiV1RegionsIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<RegionDto> {
            return localVarFp.apiV1RegionsIdGet(requestParameters.id, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of all promoted regions.
         * @param {RegionsApiApiV1RegionsPromotedGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegionsPromotedGet(requestParameters: RegionsApiApiV1RegionsPromotedGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<CityDto>> {
            return localVarFp.apiV1RegionsPromotedGet(requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the region that matches the search word.
         * @param {RegionsApiApiV1RegionsSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegionsSearchGet(requestParameters: RegionsApiApiV1RegionsSearchGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RegionDto> {
            return localVarFp.apiV1RegionsSearchGet(requestParameters.name, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegionsApi - interface
 * @export
 * @interface RegionsApi
 */
export interface RegionsApiInterface {
    /**
     * 
     * @summary Gets a structured list of all regions.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApiInterface
     */
    apiV1RegionsGet(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<RegionThumbnailDto>>;

    /**
     * 
     * @summary Gets the region related data.
     * @param {number} id The region identifier.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApiInterface
     */
    apiV1RegionsIdGet(id: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<RegionDto>;

    /**
     * 
     * @summary Gets a list of all promoted regions.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApiInterface
     */
    apiV1RegionsPromotedGet(xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<CityDto>>;

    /**
     * 
     * @summary Gets the region that matches the search word.
     * @param {string} [name] The search word.
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApiInterface
     */
    apiV1RegionsSearchGet(name?: string, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<RegionDto>;

}

/**
 * Request parameters for apiV1RegionsGet operation in RegionsApi.
 * @export
 * @interface RegionsApiApiV1RegionsGetRequest
 */
export interface RegionsApiApiV1RegionsGetRequest {
    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof RegionsApiApiV1RegionsGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof RegionsApiApiV1RegionsGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1RegionsIdGet operation in RegionsApi.
 * @export
 * @interface RegionsApiApiV1RegionsIdGetRequest
 */
export interface RegionsApiApiV1RegionsIdGetRequest {
    /**
     * The region identifier.
     * @type {number}
     * @memberof RegionsApiApiV1RegionsIdGet
     */
    readonly id: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof RegionsApiApiV1RegionsIdGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof RegionsApiApiV1RegionsIdGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1RegionsPromotedGet operation in RegionsApi.
 * @export
 * @interface RegionsApiApiV1RegionsPromotedGetRequest
 */
export interface RegionsApiApiV1RegionsPromotedGetRequest {
    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof RegionsApiApiV1RegionsPromotedGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof RegionsApiApiV1RegionsPromotedGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1RegionsSearchGet operation in RegionsApi.
 * @export
 * @interface RegionsApiApiV1RegionsSearchGetRequest
 */
export interface RegionsApiApiV1RegionsSearchGetRequest {
    /**
     * The search word.
     * @type {string}
     * @memberof RegionsApiApiV1RegionsSearchGet
     */
    readonly name?: string

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof RegionsApiApiV1RegionsSearchGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof RegionsApiApiV1RegionsSearchGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * RegionsApi - object-oriented interface
 * @export
 * @class RegionsApi
 * @extends {BaseAPI}
 */
export class RegionsApi extends BaseAPI implements RegionsApiInterface {
    /**
     * 
     * @summary Gets a structured list of all regions.
     * @param {RegionsApiApiV1RegionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public apiV1RegionsGet(requestParameters: RegionsApiApiV1RegionsGetRequest = {}, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).apiV1RegionsGet(requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the region related data.
     * @param {RegionsApiApiV1RegionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public apiV1RegionsIdGet(requestParameters: RegionsApiApiV1RegionsIdGetRequest, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).apiV1RegionsIdGet(requestParameters.id, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of all promoted regions.
     * @param {RegionsApiApiV1RegionsPromotedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public apiV1RegionsPromotedGet(requestParameters: RegionsApiApiV1RegionsPromotedGetRequest = {}, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).apiV1RegionsPromotedGet(requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the region that matches the search word.
     * @param {RegionsApiApiV1RegionsSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public apiV1RegionsSearchGet(requestParameters: RegionsApiApiV1RegionsSearchGetRequest = {}, options?: AxiosRequestConfig) {
        return RegionsApiFp(this.configuration).apiV1RegionsSearchGet(requestParameters.name, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteMapApi - axios parameter creator
 * @export
 */
export const SiteMapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of public boats for site map purposes.
         * @param {number} [pageNumber] The page number of the pagination (page size 100).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitemapBoatsGet: async (pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sitemap/boats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of public landing pages for site map purposes.
         * @param {number} [pageNumber] The page number of the pagination (page size 100).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitemapLandingPagesGet: async (pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sitemap/landing-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of public regions for site map purposes.
         * @param {number} [pageNumber] The page number of the pagination (page size 100).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitemapRegionsGet: async (pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/sitemap/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (xLanguage != null) {
                localVarHeaderParameter['x-language'] = typeof xLanguage === 'string' 
                    ? xLanguage 
                    : JSON.stringify(xLanguage);
            }

            if (xCurrency != null) {
                localVarHeaderParameter['x-currency'] = typeof xCurrency === 'string' 
                    ? xCurrency 
                    : JSON.stringify(xCurrency);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteMapApi - functional programming interface
 * @export
 */
export const SiteMapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteMapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of public boats for site map purposes.
         * @param {number} [pageNumber] The page number of the pagination (page size 100).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitemapBoatsGet(pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoatSiteMapItemDataList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitemapBoatsGet(pageNumber, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of public landing pages for site map purposes.
         * @param {number} [pageNumber] The page number of the pagination (page size 100).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitemapLandingPagesGet(pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISiteMapIndexItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitemapLandingPagesGet(pageNumber, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of public regions for site map purposes.
         * @param {number} [pageNumber] The page number of the pagination (page size 100).
         * @param {LanguageCode} [xLanguage] Culture info language
         * @param {CurrencyCode} [xCurrency] Culture info currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitemapRegionsGet(pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ISiteMapIndexItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitemapRegionsGet(pageNumber, xLanguage, xCurrency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteMapApi - factory interface
 * @export
 */
export const SiteMapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteMapApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of public boats for site map purposes.
         * @param {SiteMapApiApiV1SitemapBoatsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitemapBoatsGet(requestParameters: SiteMapApiApiV1SitemapBoatsGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BoatSiteMapItemDataList> {
            return localVarFp.apiV1SitemapBoatsGet(requestParameters.pageNumber, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of public landing pages for site map purposes.
         * @param {SiteMapApiApiV1SitemapLandingPagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitemapLandingPagesGet(requestParameters: SiteMapApiApiV1SitemapLandingPagesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ISiteMapIndexItem>> {
            return localVarFp.apiV1SitemapLandingPagesGet(requestParameters.pageNumber, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of public regions for site map purposes.
         * @param {SiteMapApiApiV1SitemapRegionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitemapRegionsGet(requestParameters: SiteMapApiApiV1SitemapRegionsGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ISiteMapIndexItem>> {
            return localVarFp.apiV1SitemapRegionsGet(requestParameters.pageNumber, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteMapApi - interface
 * @export
 * @interface SiteMapApi
 */
export interface SiteMapApiInterface {
    /**
     * 
     * @summary Gets a list of public boats for site map purposes.
     * @param {number} [pageNumber] The page number of the pagination (page size 100).
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteMapApiInterface
     */
    apiV1SitemapBoatsGet(pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<BoatSiteMapItemDataList>;

    /**
     * 
     * @summary Gets a list of public landing pages for site map purposes.
     * @param {number} [pageNumber] The page number of the pagination (page size 100).
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteMapApiInterface
     */
    apiV1SitemapLandingPagesGet(pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<ISiteMapIndexItem>>;

    /**
     * 
     * @summary Gets a list of public regions for site map purposes.
     * @param {number} [pageNumber] The page number of the pagination (page size 100).
     * @param {LanguageCode} [xLanguage] Culture info language
     * @param {CurrencyCode} [xCurrency] Culture info currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteMapApiInterface
     */
    apiV1SitemapRegionsGet(pageNumber?: number, xLanguage?: LanguageCode, xCurrency?: CurrencyCode, options?: AxiosRequestConfig): AxiosPromise<Array<ISiteMapIndexItem>>;

}

/**
 * Request parameters for apiV1SitemapBoatsGet operation in SiteMapApi.
 * @export
 * @interface SiteMapApiApiV1SitemapBoatsGetRequest
 */
export interface SiteMapApiApiV1SitemapBoatsGetRequest {
    /**
     * The page number of the pagination (page size 100).
     * @type {number}
     * @memberof SiteMapApiApiV1SitemapBoatsGet
     */
    readonly pageNumber?: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof SiteMapApiApiV1SitemapBoatsGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof SiteMapApiApiV1SitemapBoatsGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1SitemapLandingPagesGet operation in SiteMapApi.
 * @export
 * @interface SiteMapApiApiV1SitemapLandingPagesGetRequest
 */
export interface SiteMapApiApiV1SitemapLandingPagesGetRequest {
    /**
     * The page number of the pagination (page size 100).
     * @type {number}
     * @memberof SiteMapApiApiV1SitemapLandingPagesGet
     */
    readonly pageNumber?: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof SiteMapApiApiV1SitemapLandingPagesGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof SiteMapApiApiV1SitemapLandingPagesGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * Request parameters for apiV1SitemapRegionsGet operation in SiteMapApi.
 * @export
 * @interface SiteMapApiApiV1SitemapRegionsGetRequest
 */
export interface SiteMapApiApiV1SitemapRegionsGetRequest {
    /**
     * The page number of the pagination (page size 100).
     * @type {number}
     * @memberof SiteMapApiApiV1SitemapRegionsGet
     */
    readonly pageNumber?: number

    /**
     * Culture info language
     * @type {LanguageCode}
     * @memberof SiteMapApiApiV1SitemapRegionsGet
     */
    readonly xLanguage?: LanguageCode

    /**
     * Culture info currency
     * @type {CurrencyCode}
     * @memberof SiteMapApiApiV1SitemapRegionsGet
     */
    readonly xCurrency?: CurrencyCode
}

/**
 * SiteMapApi - object-oriented interface
 * @export
 * @class SiteMapApi
 * @extends {BaseAPI}
 */
export class SiteMapApi extends BaseAPI implements SiteMapApiInterface {
    /**
     * 
     * @summary Gets a list of public boats for site map purposes.
     * @param {SiteMapApiApiV1SitemapBoatsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteMapApi
     */
    public apiV1SitemapBoatsGet(requestParameters: SiteMapApiApiV1SitemapBoatsGetRequest = {}, options?: AxiosRequestConfig) {
        return SiteMapApiFp(this.configuration).apiV1SitemapBoatsGet(requestParameters.pageNumber, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of public landing pages for site map purposes.
     * @param {SiteMapApiApiV1SitemapLandingPagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteMapApi
     */
    public apiV1SitemapLandingPagesGet(requestParameters: SiteMapApiApiV1SitemapLandingPagesGetRequest = {}, options?: AxiosRequestConfig) {
        return SiteMapApiFp(this.configuration).apiV1SitemapLandingPagesGet(requestParameters.pageNumber, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of public regions for site map purposes.
     * @param {SiteMapApiApiV1SitemapRegionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteMapApi
     */
    public apiV1SitemapRegionsGet(requestParameters: SiteMapApiApiV1SitemapRegionsGetRequest = {}, options?: AxiosRequestConfig) {
        return SiteMapApiFp(this.configuration).apiV1SitemapRegionsGet(requestParameters.pageNumber, requestParameters.xLanguage, requestParameters.xCurrency, options).then((request) => request(this.axios, this.basePath));
    }
}


